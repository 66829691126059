"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.1
 */
'use client';

import { jsx, jsxs, Fragment } from 'react/jsx-runtime';
import { isFormula } from '@integration-app/sdk';
import { useEffect } from 'react';
import { isDefinedValue } from '../ComboBox/Value/Schema.mjs';
import { NestedLevel, Row, Cell } from '../DataBuilderUI/index.mjs';
import useDataBuilder from './data-builder-context.mjs';
import { isFieldVisible } from './data-field.mjs';
import useDataBuilderField, { DataBuilderFieldProvider } from './field-context.mjs';
import { FieldDelete } from './FieldDelete.mjs';
import FieldName from './FieldName.mjs';
import { FieldTypeSelector, convertableSchemaToSchema } from './FieldTypeSelector/index.mjs';
import DataBuilderFormFieldValue from './FieldValue/index.mjs';
import DataBuilderFormNested from './Nested.mjs';

function DataBuilderFormField({
  field,
  onChange,
  onKeyChange,
  onDelete,
  onEnter
}) {
  const { hideReadOnlyFields } = useDataBuilder();
  if (field.schema?.readOnly && hideReadOnlyFields) {
    return null;
  }
  return /* @__PURE__ */ jsx(DataBuilderFieldProvider, { field, children: /* @__PURE__ */ jsx(
    DataBuilderFieldContent,
    {
      onChange,
      onKeyChange,
      onDelete,
      onEnter
    }
  ) });
}
function DataBuilderFieldContent({
  onChange,
  onKeyChange,
  onDelete,
  onEnter
}) {
  const { isReadOnly } = useDataBuilder();
  const {
    field,
    localSchema,
    setLocalSchema,
    resetLocalSchema,
    localSchemaChangedManually,
    setLocalSchemaChangedManually,
    autoOpen,
    setAutoOpen
  } = useDataBuilderField();
  useEffect(() => {
    if (!localSchemaChangedManually) {
      resetLocalSchema();
    }
  }, [JSON.stringify(field?.schema)]);
  async function handleChange(value) {
    onChange?.(value);
  }
  function handleKeyChange(newKey) {
    onKeyChange?.(newKey);
  }
  function handleEnterKey(value) {
    if (value && onEnter) {
      onEnter?.();
    }
  }
  function handleEscKey(value) {
    if (!value && onDelete) {
      onDelete?.();
    }
  }
  function handleLocalSchemaTypeChange(schemaType) {
    const showValueDropdown = !(schemaType === "object" || schemaType === "array");
    const resetValue = !convertableSchemaToSchema(localSchema?.type, schemaType);
    setLocalSchemaChangedManually(true);
    setLocalSchema({ ...localSchema, type: schemaType });
    if (resetValue) {
      onChange?.("");
    }
    setAutoOpen(showValueDropdown);
  }
  let showValue = true;
  const fieldIsObjectOrArray = ["object", "array"].includes(
    localSchema?.type
  );
  const fieldHasCustomValue = !isFormula(field?.value);
  if (fieldIsObjectOrArray && fieldHasCustomValue && !autoOpen) {
    showValue = false;
  }
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(NestedLevel, { level: field.level, children: isFieldVisible(field) && /* @__PURE__ */ jsxs(Row, { short: !showValue, children: [
      (isDefinedValue(field.key) || field.schema?.title) && /* @__PURE__ */ jsx(Cell.Name, { children: /* @__PURE__ */ jsx(
        FieldName,
        {
          field,
          onKeyChange: handleKeyChange,
          onEnter: handleEnterKey,
          onEsc: handleEscKey,
          typeSelectorSlot: /* @__PURE__ */ jsx(
            FieldTypeSelector,
            {
              onChange: handleChange,
              onSchemaChange: handleLocalSchemaTypeChange
            }
          )
        }
      ) }),
      showValue && /* @__PURE__ */ jsx(Cell.Combobox, { grow: true, children: /* @__PURE__ */ jsx(FieldDataInput, { onChange: handleChange }) }),
      field.isDeletable && !isReadOnly && /* @__PURE__ */ jsx(FieldDelete, { onClick: onDelete })
    ] }) }),
    /* @__PURE__ */ jsx(DataBuilderFormNested, { field, onChange: handleChange })
  ] });
}
function FieldDataInput({
  onChange
}) {
  const { field, localSchema, setLocalSchemaChangedManually } = useDataBuilderField();
  function handleChange(value) {
    setLocalSchemaChangedManually(false);
    onChange(value);
  }
  const fieldWithDefinedSchema = !field.schema?.type ? {
    ...field,
    schema: {
      ...field.schema,
      ...localSchema,
      anyOf: void 0
    }
  } : field;
  return /* @__PURE__ */ jsx(DataBuilderFormFieldValue, { field: fieldWithDefinedSchema, onChange: handleChange });
}

export { DataBuilderFormField as default };
