"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.1
 */
'use client';

import { filterOptionsByLabel } from '../../ComboBox/options-factories/base.mjs';
import { ValueType } from '../../ComboBox/value-spec.mjs';
import '../../SvgIcon/index.mjs';
import { getFormulas } from './index.mjs';

class FormulaListOptionsFactory {
  async getOptions({ input, spec }) {
    const formulaTypes = getFormulaTypesForSpec(spec);
    const options = [];
    for (const type of formulaTypes) {
      const formulaSpec = getFormulas()[type];
      options.push({
        label: formulaSpec.name,
        value: {
          [`$${type}`]: null
        }
      });
    }
    const filteredOptions = filterOptionsByLabel(options, input);
    return filteredOptions;
  }
}
function getFormulaTypesForSpec(spec) {
  const result = [];
  for (const [formulaType, formulaSpec] of Object.entries(getFormulas())) {
    if (!formulaSpec.appliesToSpec) {
      continue;
    }
    if (!spec?.type || spec.type === ValueType.ANY || formulaSpec.appliesToSpec(spec)) {
      result.push(formulaType);
    }
  }
  return result;
}

export { FormulaListOptionsFactory };
