"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.1
 */
'use client';

import { getValueSpecFromSchema, doesSchemaMatchValueType } from '../ComboBox/value-spec.mjs';
import useDataBuilderField from './field-context.mjs';

function useDataBuilderFieldCanUseVarFromVariables() {
  const { field, localSchema } = useDataBuilderField();
  const localSchemaIsArrayOrObject = ["array", "object"].includes(
    localSchema?.type || ""
  );
  if (!localSchemaIsArrayOrObject) {
    return false;
  }
  const valueSpec = getValueSpecFromSchema(localSchema);
  const hasMatchedVariables = Object.values(
    field?.variablesSchema?.properties ?? {}
  ).some((schema) => doesSchemaMatchValueType(schema, valueSpec, true));
  const hasOptionFactories = (field?.optionFactories ?? []).length > 0;
  return hasMatchedVariables || hasOptionFactories;
}

export { useDataBuilderFieldCanUseVarFromVariables };
