"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.1
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import ArrayFieldValue from './Array.mjs';
import DefaultFieldValue from './Default.mjs';
import ScalarArrayFieldValue from './ScalarArray.mjs';
import { schemaIsScalarArray } from '../data-field.mjs';

function DataBuilderFormFieldValue({
  field,
  onChange,
  allowScalarArray,
  refsToIgnoreClick,
  topDropdownPanelComponent
}) {
  if (allowScalarArray && schemaIsScalarArray(field.schema)) {
    return /* @__PURE__ */ jsx(ScalarArrayFieldValue, { field, onChange });
  }
  if (field.schema?.type === "array") {
    return /* @__PURE__ */ jsx(
      ArrayFieldValue,
      {
        field,
        onChange,
        refsToIgnoreClick,
        topDropdownPanelComponent
      }
    );
  } else {
    return /* @__PURE__ */ jsx(
      DefaultFieldValue,
      {
        field,
        onChange,
        refsToIgnoreClick,
        topDropdownPanelComponent
      }
    );
  }
}

export { DataBuilderFormFieldValue as default };
