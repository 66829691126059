"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.1
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import DefaultFieldValue from './Default.mjs';

function ArrayFieldValue({
  field,
  onChange,
  refsToIgnoreClick,
  topDropdownPanelComponent
}) {
  if (Array.isArray(field.value) && field.value.length > 0 && field.schema?.items) {
    return null;
  }
  return /* @__PURE__ */ jsx(
    DefaultFieldValue,
    {
      field,
      onChange,
      refsToIgnoreClick,
      topDropdownPanelComponent
    }
  );
}

export { ArrayFieldValue as default };
