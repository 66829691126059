/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.1
 */
'use client';

import { getPatternStyles, patternFns } from '../helpers.mjs';
import '../css/css.mjs';

const visuallyHiddenConfig = {
transform(props) {
  return {
    srOnly: true,
    ...props
  };
}};

const getVisuallyHiddenStyle = (styles = {}) => {
  const _styles = getPatternStyles(visuallyHiddenConfig, styles);
  return visuallyHiddenConfig.transform(_styles, patternFns)
};

export { getVisuallyHiddenStyle };
