"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.1
 */
'use client';

import { WorkspaceElementType } from '@integration-app/sdk';
import { useElement } from '../useElement.mjs';

function useExternalEventSubscription(id) {
  const {
    item: externalEventSubscription,
    accessor,
    refresh,
    ...rest
  } = useElement(
    WorkspaceElementType.ExternalEventSubscription,
    id,
    (integrationApp) => id ? integrationApp.externalEventSubscription(id) : void 0
  );
  async function setup() {
    await accessor?.setup();
    await refresh();
  }
  async function subscribe() {
    await accessor?.subscribe();
    await refresh();
  }
  async function resubscribe() {
    await accessor?.resubscribe();
    await refresh();
  }
  async function unsubscribe() {
    await accessor?.unsubscribe();
    await refresh();
  }
  async function pullEvents() {
    await accessor?.pullEvents();
    await refresh();
  }
  return {
    externalEventSubscription,
    accessor,
    refresh,
    setup,
    subscribe,
    resubscribe,
    unsubscribe,
    pullEvents,
    ...rest
  };
}

export { useExternalEventSubscription };
