"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.1
 */
'use client';

import { jsxs, jsx } from 'react/jsx-runtime';
import { forwardRef } from 'react';
import { ComboBoxTag } from '../../ComboBoxElements/ComboBoxTag.mjs';
import { Tag } from '../../Tag/index.mjs';
import { Truncate } from '../../Truncate.mjs';
import { SvgIconType } from '../../SvgIcon/index.mjs';

const LookupFormula = forwardRef((props, ref) => {
  const { formula } = props;
  const isConfigError = Object.entries(formula.value?.query ?? {}).length === 0;
  return /* @__PURE__ */ jsxs(ComboBoxTag, { ref, variant: "lookup", children: [
    isConfigError && /* @__PURE__ */ jsx(Tag.SvgIcon, { type: SvgIconType.AlertTriangle }),
    /* @__PURE__ */ jsx(Truncate, { children: "Select Dynamically Using Variables" })
  ] });
});
LookupFormula.displayName = "LookupFormula";

export { LookupFormula as default };
