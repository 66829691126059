"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.1
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import { ComboBox } from '../../ComboBox/index.mjs';
import useDataBuilder from '../data-builder-context.mjs';
import useDataBuilderField from '../field-context.mjs';
import { getValueSpecFromSchema } from '../../ComboBox/value-spec.mjs';
import { getFieldValueProps } from '../data-field.mjs';

function DefaultFieldValue({
  field,
  onChange,
  refsToIgnoreClick,
  topDropdownPanelComponent
}) {
  const { isEmpty, isRequired } = getFieldValueProps(field);
  const { isReadOnly, frozenFieldsLocators } = useDataBuilder();
  const { autoOpen, setAutoOpen } = useDataBuilderField();
  const isFrozenField = frozenFieldsLocators.some(
    (frozenFieldLocator) => frozenFieldLocator && field.valueLocator?.startsWith(frozenFieldLocator)
  );
  function handleDropdownClose() {
    setAutoOpen(false);
  }
  return /* @__PURE__ */ jsx(
    ComboBox,
    {
      value: isEmpty ? void 0 : field.value,
      schema: field.schema,
      onChange,
      valueSpec: getValueSpecFromSchema(field.schema),
      variablesSchema: field.variablesSchema,
      optionFactories: field.optionFactories,
      isRequired,
      disabled: isFrozenField || isReadOnly,
      autoOpen,
      onClose: handleDropdownClose,
      refsToIgnoreClick,
      topDropdownPanelComponent
    }
  );
}

export { DefaultFieldValue as default };
