"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.1
 */
'use client';

import { UNIFIED_DATA_MODELS } from '@integration-app/sdk';
import { SvgIconType } from '../../SvgIcon/index.mjs';
import { filterOptionsByLabel } from './base.mjs';

class ReferenceUdmOptionFactory {
  constructor(schema) {
    this.schema = schema;
  }
  async getOptions({ input }) {
    const options = [];
    const udm = this.schema?.referenceUdm;
    const udmSpec = UNIFIED_DATA_MODELS[udm];
    if (udmSpec) {
      options.push({
        label: "Select Dynamically Using Variables",
        iconType: SvgIconType.Search,
        value: {
          $lookup: {}
        }
      });
    }
    return filterOptionsByLabel(options, input);
  }
}

export { ReferenceUdmOptionFactory };
