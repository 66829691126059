"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.1
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import { useComboBoxDropdownContext } from '../context/combobox-dropdown-context.mjs';
import { TAB_COMPONENTS } from './tabs.mjs';

function ActiveTab({ onSelect }) {
  const { activeTab } = useComboBoxDropdownContext();
  const ActiveTab2 = (activeTab ? TAB_COMPONENTS[activeTab] : void 0) ?? (() => /* @__PURE__ */ jsx("p", { children: "Unknown Tab" }));
  return /* @__PURE__ */ jsx(ActiveTab2, { onSelect });
}

export { ActiveTab };
