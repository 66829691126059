"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.1
 */
'use client';

import { UNIFIED_DATA_MODELS, schemaHasFixedValues, getReferenceCollectionPathForSchema, getFormula, DataBuilderFormulaType } from '@integration-app/sdk';
import { ComboBoxTabType } from '../types.mjs';
import { getLookupCollection } from '../Dropdown/Tabs/Lookup.mjs';

function isSchemaAllowCustomValue(schema) {
  const schemaHasNoType = !schema?.type;
  const schemaIsObjectWithNoProperties = schema?.type === "object" && Object.keys(schema.properties ?? {}).length === 0;
  const schemaIsArrayWithNoSchemaItems = schema?.type === "array" && !schema?.items?.type;
  return schemaHasNoType || schemaIsObjectWithNoProperties || schemaIsArrayWithNoSchemaItems;
}
async function getAvailableTabs({
  schema,
  options,
  optionFactories,
  variablesOnly,
  hasVariables,
  client,
  connectionId,
  integrationId
}) {
  const availableTabs = [];
  const udm = schema?.referenceUdm;
  const udmSpec = udm ? UNIFIED_DATA_MODELS[udm] : void 0;
  if (!!udmSpec) {
    availableTabs.push(ComboBoxTabType.SELECT_FROM_UDM_DYNAMICALLY);
  }
  const comboboxHasOptions = Array.isArray(options);
  const comboboxHasOptionFactories = (optionFactories || []).length > 0;
  const comboboxHasFixedValues = !!schema && schemaHasFixedValues(schema);
  const comboboxHasOptionList = comboboxHasOptions || comboboxHasOptionFactories || comboboxHasFixedValues;
  if (comboboxHasOptionList) {
    availableTabs.push(ComboBoxTabType.OPTIONS);
  }
  const comboboxHasReferenceCollection = !!getReferenceCollectionPathForSchema(schema);
  if (comboboxHasReferenceCollection && connectionId) {
    availableTabs.push(ComboBoxTabType.LOOKUP_OPTIONS);
  }
  if (schema && (connectionId || integrationId)) {
    const collection = await getLookupCollection({
      schema,
      client,
      connectionId,
      integrationId
    });
    if (collection?.find?.queryFields || collection?.match?.fields) {
      availableTabs.push(ComboBoxTabType.SELECT_FROM_COLLECTION_DYNAMICALLY);
    }
  }
  const schemaAllowCustomValue = isSchemaAllowCustomValue(schema);
  if (!variablesOnly && !comboboxHasOptionList && schemaAllowCustomValue) {
    availableTabs.push(ComboBoxTabType.CUSTOM);
  }
  if (hasVariables) {
    availableTabs.push(ComboBoxTabType.VARIABLE);
    availableTabs.push(ComboBoxTabType.FORMULA);
  }
  return availableTabs.length > 0 ? availableTabs : null;
}
function getCurrentTab(availableTabs, possibleCurrentTab, value) {
  if (possibleCurrentTab && availableTabs.includes(possibleCurrentTab)) {
    return possibleCurrentTab;
  }
  return getDefaultTab(availableTabs, value);
}
function getDefaultTab(availableTabs, value) {
  if (availableTabs.length <= 1) {
    return availableTabs[0];
  }
  const formulaType = getFormula(value)?.type;
  if (formulaType === DataBuilderFormulaType.LOOKUP) {
    if (availableTabs.includes(ComboBoxTabType.SELECT_FROM_COLLECTION_DYNAMICALLY)) {
      return ComboBoxTabType.SELECT_FROM_COLLECTION_DYNAMICALLY;
    }
    if (availableTabs.includes(ComboBoxTabType.SELECT_FROM_UDM_DYNAMICALLY)) {
      return ComboBoxTabType.SELECT_FROM_UDM_DYNAMICALLY;
    }
  }
  const generalFormulaTypes = Object.values(DataBuilderFormulaType).filter(
    (x) => ![
      DataBuilderFormulaType.TPL,
      DataBuilderFormulaType.VAR,
      DataBuilderFormulaType.RECORD
    ].includes(x)
  );
  if (availableTabs.includes(ComboBoxTabType.FORMULA) && generalFormulaTypes.includes(formulaType)) {
    return ComboBoxTabType.FORMULA;
  }
  if (availableTabs.includes(ComboBoxTabType.OPTIONS)) {
    return ComboBoxTabType.OPTIONS;
  }
  if (availableTabs.includes(ComboBoxTabType.LOOKUP_OPTIONS)) {
    return ComboBoxTabType.LOOKUP_OPTIONS;
  }
  if (availableTabs.includes(ComboBoxTabType.VARIABLE)) {
    return ComboBoxTabType.VARIABLE;
  }
  return availableTabs[0];
}
function canUserEnterValueFromKeyboard(schema, options, optionFactories, variablesOnly, value) {
  const comboboxHasOptions = (options || []).length > 0;
  const comboboxHasOptionFactories = (optionFactories || []).length > 0;
  if (variablesOnly || comboboxHasOptions || comboboxHasOptionFactories) {
    return false;
  }
  const valueIsLookup = !!value?.$lookup;
  if (valueIsLookup) {
    return false;
  }
  if (schema && schemaHasFixedValues(schema)) {
    return !!schema.allowCustom;
  }
  if (schema?.type && ["array", "object", "boolean"].includes(schema?.type)) {
    return false;
  }
  return true;
}

export { canUserEnterValueFromKeyboard, getAvailableTabs, getCurrentTab };
