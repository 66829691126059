"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.1
 */
'use client';

import { jsx, jsxs } from 'react/jsx-runtime';
import { forwardRef, useLayoutEffect } from 'react';
import clsx from 'clsx';
import { useIntersectionObserver } from 'usehooks-ts';
import { SvgIconType } from '../SvgIcon/index.mjs';
import '../Input/Input.mjs';
import { SearchInput } from '../Input/SearchInput.mjs';
import useEventStopPropagation from '../../hooks/useEventStopPropagation.mjs';
import { ItemIcon, ItemTitle } from './DropdownUIElements.mjs';
import * as DropdownUIElements from './DropdownUIElements.mjs';
export { DropdownUIElements };
import classes from './DropdownUI.module.css.mjs';
import { useForwardedRef } from '../../hooks/useForwardedRef.mjs';
export { DropdownTab } from './DropdownUITab.mjs';

const DropdownRoot = forwardRef(
  ({ children, className, ...props }, ref) => {
    return /* @__PURE__ */ jsx("div", { ref, className: clsx(classes.dropdown, className), ...props, children });
  }
);
function DropdownTabs({ children, ...props }) {
  return /* @__PURE__ */ jsx("div", { className: classes.dropdown_tabs, ...props, children });
}
function DropdownTab({ children, selected, ...props }) {
  return /* @__PURE__ */ jsx(
    "button",
    {
      type: "button",
      role: "tab",
      className: classes.dropdown_tab,
      "aria-selected": selected || void 0,
      ...props,
      children
    }
  );
}
const DropdownSearch = forwardRef(
  ({ value, onChange, autoFocus, ...props }, ref) => {
    function handleSearchChange(event) {
      onChange?.(event.target.value);
    }
    const handleEventAndStopPropagation = useEventStopPropagation(true);
    return /* @__PURE__ */ jsx(
      DropdownPanel,
      {
        className: classes.dropdown_searchWrapper,
        ...props,
        ref,
        children: /* @__PURE__ */ jsx(
          SearchInput,
          {
            variant: "border",
            className: classes.dropdown_searchInput,
            placeholder: props.placeholder || "Search",
            value,
            autoFocus,
            onChange: handleSearchChange,
            onKeyUp: handleEventAndStopPropagation,
            onClick: handleEventAndStopPropagation
          }
        )
      }
    );
  }
);
const DropdownPanel = forwardRef(
  ({ withPaddings, className, children, ...props }, ref) => {
    return /* @__PURE__ */ jsx(
      "div",
      {
        className: clsx(
          classes.dropdown_panel,
          className,
          withPaddings && classes.dropdown_panel__withPaddings
        ),
        ref,
        ...props,
        children
      }
    );
  }
);
const DropdownList = forwardRef(
  ({ children, ...props }, ref) => {
    return /* @__PURE__ */ jsx("div", { className: classes.dropdown_optionList, ...props, ref, children });
  }
);
const DropdownItem = forwardRef(
  ({
    children,
    className,
    active = false,
    selected = false,
    disabled = false,
    readonly = false,
    hasError = false,
    isHighlighted = false,
    ...props
  }, ref) => {
    return /* @__PURE__ */ jsx(
      DropdownItemDiv,
      {
        ref,
        active,
        className: clsx(
          classes.dropdown_optionItem,
          isHighlighted && classes.dropdown_optionItem__highlighted,
          className
        ),
        "aria-selected": selected || void 0,
        "aria-current": active || void 0,
        "aria-readonly": readonly || void 0,
        "aria-disabled": disabled || void 0,
        "aria-invalid": hasError || void 0,
        role: "listitem",
        ...props,
        children
      }
    );
  }
);
function DropdownLoadingPanel({ children }) {
  return /* @__PURE__ */ jsx(DropdownPanel, { children: /* @__PURE__ */ jsx(DropdownList, { children: /* @__PURE__ */ jsxs(DropdownItem, { readonly: true, children: [
    /* @__PURE__ */ jsx(ItemIcon, { type: SvgIconType.Loader }),
    /* @__PURE__ */ jsx(ItemTitle, { children })
  ] }) }) });
}
function DropdownErrorPanel({ children }) {
  return /* @__PURE__ */ jsx(DropdownPanel, { children: /* @__PURE__ */ jsx(DropdownList, { children: /* @__PURE__ */ jsxs(DropdownItem, { hasError: true, children: [
    /* @__PURE__ */ jsx(ItemIcon, { type: SvgIconType.AlertTriangle }),
    /* @__PURE__ */ jsx(ItemTitle, { children })
  ] }) }) });
}
function DropdownHeaderItem({
  children,
  className,
  clickable,
  active,
  ...props
}) {
  return /* @__PURE__ */ jsx(
    DropdownItem,
    {
      className: clsx(
        classes.dropdown_optionItem__header,
        clickable && classes.dropdown_optionItem__clickableHeader,
        className
      ),
      "aria-current": active || void 0,
      role: "listitem",
      ...props,
      children
    }
  );
}
const DropdownUI = Object.assign(DropdownRoot, {
  Tabs: DropdownTabs,
  Tab: DropdownTab,
  Search: DropdownSearch,
  Panel: DropdownPanel,
  List: DropdownList,
  Item: DropdownItem,
  HeaderItem: DropdownHeaderItem,
  LoadingPanel: DropdownLoadingPanel,
  ErrorPanel: DropdownErrorPanel
});
const DropdownItemDiv = forwardRef(
  ({ children, active, ...props }, ref) => {
    if (active) {
      return /* @__PURE__ */ jsx(ScrollIntoViewDiv, { ...props, children });
    }
    return /* @__PURE__ */ jsx("div", { ref, ...props, children });
  }
);
const ScrollIntoViewDiv = forwardRef(({ children, ...props }, ref) => {
  const actualRef = useForwardedRef(ref);
  useScrollIntoViewOnce(actualRef);
  return /* @__PURE__ */ jsx("div", { ...props, ref: actualRef, children });
});
function useScrollIntoViewOnce(ref) {
  const threshold = 0.7;
  const freezeOnceVisible = true;
  const entry = useIntersectionObserver(ref, {
    threshold,
    freezeOnceVisible
  });
  useLayoutEffect(() => {
    if (!entry) {
      return;
    }
    if (
      // Hack for TS
      // IntersectionObserverEntry.isVisible is not in the type definition
      entry["isVisible"] || entry.isIntersecting && entry.intersectionRatio > 0.7
    ) {
      return;
    }
    entry.target.scrollIntoView({
      block: "nearest"
    });
  }, [entry]);
}

export { DropdownUI, ItemTitle };
