/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.1
 */
'use client';

import styleInject from 'style-inject';

var css_248z = ".ParametersPanel-module_panel__uGStt {\n    display: flex;\n    flex-direction: column;\n    gap: 0.5rem;\n    padding: 0.5rem\n}\n.ParametersPanel-module_panel__collapsed__yLwz3 {\n    --tw-bg-opacity: 1;\n    background-color: rgb(238 238 238 / var(--tw-bg-opacity))\n}\n.ParametersPanel-module_panel__expanded__xhz7X {\n    padding-bottom: 0.75rem\n}\n";
var classes = {"panel":"ParametersPanel-module_panel__uGStt","panel__collapsed":"ParametersPanel-module_panel__collapsed__yLwz3","panel__expanded":"ParametersPanel-module_panel__expanded__xhz7X"};
styleInject(css_248z);

export { classes as default };
