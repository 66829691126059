"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.1
 */
'use client';

import { jsx, jsxs, Fragment } from 'react/jsx-runtime';
import { useState, useRef, useEffect } from 'react';
import { GenericPopover } from '../Popover.mjs';
import clsx from 'clsx';
import classes from './Tooltip.module.css.mjs';

var TooltipType = /* @__PURE__ */ ((TooltipType2) => {
  TooltipType2["INFO"] = "INFO";
  TooltipType2["ERROR"] = "ERROR";
  TooltipType2["WARNING"] = "WARNING";
  return TooltipType2;
})(TooltipType || {});
function Tooltip({
  tooltip,
  type,
  className,
  children,
  ...props
}) {
  const Tooltip2 = /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx("span", { className: classes.tooltip_arrow }),
    /* @__PURE__ */ jsx("pre", { children: tooltip })
  ] });
  return /* @__PURE__ */ jsx(
    GenericTooltip,
    {
      ...props,
      tooltip: Tooltip2,
      className: clsx(classes.tooltip, getTooltipClassByType(type), className),
      children
    }
  );
}
function GenericTooltip({
  tooltip,
  placement = "right",
  offset = [0, 8],
  delayToShow = 300,
  delayToHide = 50,
  className,
  disabled,
  children
}) {
  const [isTooltipVisible, setTooltipVisible] = useState(false);
  const timerShowTooltipRef = useRef(null);
  const timerHideTooltipRef = useRef(null);
  const refElement = children?.ref ?? useRef();
  useEffect(() => {
    return () => {
      clearTimeout(timerShowTooltipRef.current);
      clearTimeout(timerHideTooltipRef.current);
    };
  }, []);
  const showTooltip = () => {
    clearTimeout(timerHideTooltipRef.current);
    timerShowTooltipRef.current = setTimeout(() => {
      setTooltipVisible(true);
    }, delayToShow);
  };
  const hideTooltip = () => {
    clearTimeout(timerShowTooltipRef.current);
    timerHideTooltipRef.current = setTimeout(() => {
      setTooltipVisible(false);
    }, delayToHide);
  };
  const modifiers = [];
  offset && modifiers.push({
    name: "offset",
    options: {
      offset
    }
  });
  const popperJsOptions = {
    placement,
    modifiers
  };
  const handleMouseEnter = () => !disabled && showTooltip();
  const handleMouseLeave = () => !disabled && hideTooltip();
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(
      children.type,
      {
        ref: refElement,
        onMouseEnter: handleMouseEnter,
        onMouseLeave: handleMouseLeave,
        "data-tooltipvisible": isTooltipVisible || void 0,
        ...{ ...children.props }
      }
    ),
    tooltip && /* @__PURE__ */ jsx(
      GenericPopover,
      {
        isOpen: isTooltipVisible,
        refElement,
        options: popperJsOptions,
        children: /* @__PURE__ */ jsx(
          "div",
          {
            className,
            onMouseEnter: handleMouseEnter,
            onMouseLeave: handleMouseLeave,
            children: tooltip
          }
        )
      }
    )
  ] });
}
function getTooltipClassByType(type) {
  switch (type) {
    case "ERROR" /* ERROR */:
      return classes.tooltip__error;
    case "WARNING" /* WARNING */:
      return classes.tooltip__warning;
    case "INFO" /* INFO */:
    default:
      return classes.tooltip__info;
  }
}

export { GenericTooltip, Tooltip, TooltipType };
