"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.1
 */
'use client';

import { jsx, jsxs, Fragment } from 'react/jsx-runtime';
import { isFormula } from '@integration-app/sdk';
import { toHeaderCase } from 'js-convert-case';
import { useId, useRef } from 'react';
import { DefaultPopper } from '../../Popover.mjs';
import { SvgIconType, SvgIcon } from '../../SvgIcon/index.mjs';
import { getFieldSchemaOptions } from '../data-field.mjs';
import useDataBuilderField from '../field-context.mjs';
import { Menu } from '@headlessui/react';
import classes from './FieldTypeSelector.module.css.mjs';
import { useDataBuilderFieldCanUseVarFromVariables } from '../hooks.mjs';

function FieldTypeSelector({
  onChange,
  onSchemaChange
}) {
  const menuContainerId = useId();
  const buttonElement = useRef(null);
  const { field, localSchema } = useDataBuilderField();
  const showUseVarFromVariables = useDataBuilderFieldCanUseVarFromVariables();
  const schemaOptions = getFieldSchemaOptions(field).map(
    (schemaOption) => ({
      value: schemaOption.type,
      label: toHeaderCase(schemaOption.type),
      iconType: SvgIconType[`SchemaType_${schemaOption.type}`],
      active: localSchema?.type === schemaOption.type
    })
  );
  const showTypeSelector = schemaOptions.length > 1;
  if (!showTypeSelector && !showUseVarFromVariables) {
    return null;
  }
  return /* @__PURE__ */ jsx(Menu, { as: "div", id: menuContainerId, children: ({ open, close }) => /* @__PURE__ */ jsxs(Fragment, { children: [
    ":",
    /* @__PURE__ */ jsx(
      Menu.Button,
      {
        className: classes.button,
        ref: buttonElement,
        "aria-label": "Change schema type",
        children: /* @__PURE__ */ jsx(SchemaType, { withChevron: true, children: localSchema?.type ?? "select" })
      }
    ),
    /* @__PURE__ */ jsx(
      DefaultPopper,
      {
        refElement: buttonElement,
        isOpen: open,
        onClickOutside: close,
        placement: "bottom-start",
        portalNode: `#${menuContainerId}`,
        children: /* @__PURE__ */ jsxs(Menu.Items, { as: "div", className: classes.menu, static: true, children: [
          showTypeSelector && /* @__PURE__ */ jsx(
            TypeSelector,
            {
              options: schemaOptions,
              onChange: onSchemaChange
            }
          ),
          showUseVarFromVariables && /* @__PURE__ */ jsx(FieldDropdown, { onChange })
        ] })
      }
    )
  ] }) });
}
function SchemaType({
  children,
  withChevron
}) {
  return /* @__PURE__ */ jsxs("div", { className: classes.schemaType, children: [
    children,
    withChevron && /* @__PURE__ */ jsx(
      SvgIcon,
      {
        className: classes.schemaType_icon,
        type: SvgIconType.ChevronDown
      }
    )
  ] });
}
function TypeSelector({
  options,
  onChange
}) {
  if (options.length <= 1) {
    return null;
  }
  return /* @__PURE__ */ jsxs(MenuList, { children: [
    /* @__PURE__ */ jsx(MenuHeader, { children: "Field Type" }),
    options.map(({ value, iconType, label, active }) => /* @__PURE__ */ jsx(
      MenuItem,
      {
        onClick: () => onChange(value),
        icon: iconType,
        "aria-current": active,
        children: label
      },
      value
    ))
  ] });
}
function FieldDropdown({ onChange }) {
  const { localSchema } = useDataBuilderField();
  switch (localSchema?.type) {
    case "object":
      return /* @__PURE__ */ jsx(FieldObjectDropdown, { onChange });
    case "array":
      return /* @__PURE__ */ jsx(FieldArrayDropdown, { onChange });
    default:
      return null;
  }
}
function FieldObjectDropdown({ onChange }) {
  const { field, setAutoOpen } = useDataBuilderField();
  function handleUseObjectFromVariables() {
    setAutoOpen(true);
  }
  function handleSetIndividualFields() {
    onChange(void 0);
  }
  const valueIsIndividualFields = !isFormula(field.value);
  return /* @__PURE__ */ jsxs(MenuList, { children: [
    /* @__PURE__ */ jsx(MenuHeader, { children: "Field Value" }),
    valueIsIndividualFields ? /* @__PURE__ */ jsx(MenuItem, { onClick: handleUseObjectFromVariables, children: "Use object from variables" }) : /* @__PURE__ */ jsx(MenuItem, { onClick: handleSetIndividualFields, children: "Set individual fields" })
  ] });
}
function FieldArrayDropdown({ onChange }) {
  const { field, setAutoOpen } = useDataBuilderField();
  function handleUseListFromVariables() {
    setAutoOpen(true);
  }
  function handleSetIndividualItems() {
    onChange(void 0);
  }
  const valueIsIndividualFields = !isFormula(field.value);
  return /* @__PURE__ */ jsxs(MenuList, { children: [
    /* @__PURE__ */ jsx(MenuHeader, { children: "Field Value" }),
    valueIsIndividualFields ? /* @__PURE__ */ jsx(MenuItem, { onClick: handleUseListFromVariables, children: "Use list from variables" }) : /* @__PURE__ */ jsx(MenuItem, { onClick: handleSetIndividualItems, children: "Set individual items" })
  ] });
}
function MenuItem({
  icon,
  children,
  ...props
}) {
  return /* @__PURE__ */ jsxs(Menu.Item, { as: "button", className: classes.menuItem, ...props, children: [
    icon && /* @__PURE__ */ jsx(SvgIcon, { type: icon, className: classes.menuItem_icon }),
    " ",
    children
  ] });
}
function MenuList({ children }) {
  return /* @__PURE__ */ jsx("div", { className: classes.menuList, children });
}
function MenuHeader({ children }) {
  return /* @__PURE__ */ jsx("h3", { className: classes.menuHeader, children });
}
function convertableSchemaToSchema(oldSchemaType, newSchemaType) {
  if (oldSchemaType === newSchemaType) {
    return true;
  }
  if ([oldSchemaType, newSchemaType].includes("object") || [oldSchemaType, newSchemaType].includes("array")) {
    return false;
  }
  return true;
}

export { FieldTypeSelector, convertableSchemaToSchema };
