"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.1
 */
'use client';

import { ark } from '@ark-ui/react';
import { styled } from '../../styled-system/jsx/factory.mjs';
import '../../styled-system/jsx/is-valid-prop.mjs';
import 'react';
import '../../styled-system/helpers.mjs';
import '../../styled-system/css/css.mjs';
import '../../styled-system/css/conditions.mjs';
import { button } from '../../styled-system/recipes/button.mjs';

const Button = styled(ark.button, button);

export { Button };
