"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.1
 */
'use client';

import { SvgIconType } from '../../SvgIcon/index.mjs';
import { ValueType } from '../value-spec.mjs';
import { filterOptionsByLabel } from './base.mjs';

const CUSTOM_OBJECT_VALUE = {};
const CUSTOM_OBJECT_VALUE_LABEL = "Object With Custom Fields";
const CUSTOM_ARRAY_VALUE = [];
const CUSTOM_ARRAY_VALUE_LABEL = "List With Custom Items";
class CustomFieldOptionsFactory {
  async getOptions({ spec, input }) {
    const options = [];
    if (spec.type === ValueType.ANY || spec.type === ValueType.OBJECT) {
      options.push({
        label: CUSTOM_OBJECT_VALUE_LABEL,
        value: CUSTOM_OBJECT_VALUE,
        iconType: SvgIconType.SchemaType_object
      });
    }
    if (spec.type === ValueType.ANY || spec.type === ValueType.ARRAY) {
      options.push({
        label: CUSTOM_ARRAY_VALUE_LABEL,
        value: CUSTOM_ARRAY_VALUE,
        iconType: SvgIconType.SchemaType_array
      });
    }
    return filterOptionsByLabel(options, input);
  }
}
function isCustomFieldValue(value) {
  return [
    JSON.stringify(CUSTOM_OBJECT_VALUE),
    JSON.stringify(CUSTOM_ARRAY_VALUE)
  ].includes(JSON.stringify(value));
}

export { CUSTOM_ARRAY_VALUE, CUSTOM_ARRAY_VALUE_LABEL, CUSTOM_OBJECT_VALUE, CUSTOM_OBJECT_VALUE_LABEL, CustomFieldOptionsFactory, isCustomFieldValue };
