"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.1
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import { createContext, useMemo, useContext } from 'react';

const IntegrationAppIntegrationContext = createContext({
  /* FIXME: strictNullCheck temporary fix */
  // @ts-expect-error TS(2322): Type 'null' is not assignable to type... Remove this comment to see the full error message
  integrationId: null
});
IntegrationAppIntegrationContext.displayName = "IntegrationAppIntegrationContext";
const IntegrationAppIntegrationProvider = ({
  integrationId,
  children
}) => {
  const value = useMemo(() => ({ integrationId }), [integrationId]);
  return /* @__PURE__ */ jsx(IntegrationAppIntegrationContext.Provider, { value, children });
};
function useIntegrationAppIntegration() {
  return useContext(IntegrationAppIntegrationContext);
}

export { IntegrationAppIntegrationProvider, useIntegrationAppIntegration };
