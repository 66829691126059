"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.1
 */
'use client';

import { jsxs, jsx } from 'react/jsx-runtime';
import { forwardRef } from 'react';
import clsx from 'clsx';
import { Tag } from '../Tag/index.mjs';
import classes from './ComboBoxTag.module.css.mjs';
import { SvgIconType } from '../SvgIcon/index.mjs';

const ComboBoxTag = forwardRef(
  function({
    // Style related props
    className,
    variant = "default",
    selected = false,
    disabled,
    invalid,
    onClick,
    tooltip,
    // Nested children props
    children,
    icon,
    iconUri,
    ...props
  }, ref) {
    return /* @__PURE__ */ jsxs(
      Tag,
      {
        className: clsx(
          classes.tagDefault,
          !!onClick && classes.tagDefault__clickable,
          selected && classes.tagDefault__selected,
          disabled && classes.tagDefault__disabled,
          invalid && classes.tagDefault__invalid,
          variant === "lookup" && classes.tagLookup,
          className
        ),
        tooltip,
        onClick,
        ref,
        ...props,
        children: [
          /* @__PURE__ */ jsx(ComboboxIcon, { invalid, icon, iconUri }),
          children
        ]
      }
    );
  }
);
ComboBoxTag.displayName = "ComboBoxTag";
function ComboboxIcon({ invalid, icon, iconUri }) {
  if (invalid) {
    return /* @__PURE__ */ jsx(Tag.SvgIcon, { type: SvgIconType.Unlink });
  }
  if (icon) {
    return /* @__PURE__ */ jsx(Tag.SvgIcon, { type: icon });
  }
  if (iconUri) {
    return /* @__PURE__ */ jsx(Tag.Image, { src: iconUri });
  }
  return null;
}
function getClassFromSchema(schema) {
  switch (schema?.type) {
    case "string":
      return classes.tagString;
    case "number":
    case "integer":
      return classes.tagNumber;
    case "boolean":
      return classes.tagBoolean;
    default:
      return classes.tagUndefined;
  }
}

export { ComboBoxTag, getClassFromSchema };
