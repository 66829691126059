"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.1
 */
'use client';

import { jsx, Fragment, jsxs } from 'react/jsx-runtime';
import { Button } from '../../../../Button/Button.mjs';
import classes from './UiComponents.module.css.mjs';

function PanelHeader({ children }) {
  return /* @__PURE__ */ jsx("h3", { className: classes.panelHeader, children });
}
function PanelHeaderButton({
  children,
  ...props
}) {
  return /* @__PURE__ */ jsx(
    Button,
    {
      size: "small",
      variant: "text",
      className: classes.panelHeader_button,
      ...props,
      children
    }
  );
}
function ParametersList({ children }) {
  return /* @__PURE__ */ jsx("div", { className: classes.parametersList, children });
}
function Parameter({ children }) {
  return /* @__PURE__ */ jsx("span", { className: classes.parameterItem, children });
}
function ParameterText({
  title,
  value
}) {
  if (!value) return /* @__PURE__ */ jsx(Fragment, { children: title });
  const truncateTo = 20;
  const truncatedValue = value.length > truncateTo ? `${value?.substring(0, truncateTo)}...` : value;
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    title,
    ": ",
    truncatedValue
  ] });
}

export { PanelHeader, PanelHeaderButton, Parameter, ParameterText, ParametersList };
