"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.1
 */
'use client';

import { TAG_EXTENSION_NAME } from '../TagComponent.mjs';
import { transformTplToValue, getTagString } from './common.mjs';

function transformEditorSchemaToValue(schema) {
  const template = (schema?.content ?? []).map((node) => transformParagraphNodeToTplString(node)).join("\n");
  const values = transformSchemaToValues(schema);
  if (Object.keys(values).length === 0) {
    return template || void 0;
  }
  return transformTplToValue(template, values);
}
function transformParagraphNodeToTplString(node) {
  return (node?.content ?? []).map((child) => transformNodeToTplString(child)).join("");
}
function transformNodeToTplString(node) {
  if (node?.type === TAG_EXTENSION_NAME) {
    return getTagString(node?.attrs?.id);
  }
  return node?.text ?? "";
}
function transformSchemaToValues(schema) {
  const entries = (schema?.content ?? []).map((node) => transformParagraphNodeToEntries(node)).flat().filter((x) => x !== void 0);
  return Object.fromEntries(entries);
}
function transformParagraphNodeToEntries(node) {
  return (node?.content ?? []).map((child) => transformNodeToEntries(child));
}
function transformNodeToEntries(node) {
  if (node?.type === TAG_EXTENSION_NAME) {
    return [node?.attrs?.id, node?.attrs?.value];
  }
  return void 0;
}

export { transformEditorSchemaToValue, transformNodeToEntries, transformNodeToTplString, transformParagraphNodeToEntries, transformParagraphNodeToTplString };
