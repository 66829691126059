"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.1
 */
'use client';

import { jsx, jsxs, Fragment } from 'react/jsx-runtime';
import { DropdownUI } from '../../../DropdownUI/index.mjs';
import usePromise from '../../../../_modules/react-use-promise.mjs';
import { getReferenceCollectionPathForSchema, schemaWithTitle, pickFieldsFromSchema, getDataCollectionCreateFields, UNIFIED_DATA_MODELS, getReferenceCollectionPointerForSchema } from '@integration-app/sdk';
import { Radiobutton } from '../../../Radio/index.mjs';
import { useIntegrationAppClient } from '../../../../contexts/integration-app-client-context.mjs';
import { useIntegrationAppConnection } from '../../../../contexts/integration-app-connection-context.mjs';
import { useIntegrationAppIntegration } from '../../../../contexts/integration-app-integration-context.mjs';
import { makeSubField } from '../../../DataBuilder/data-field.mjs';
import useDataBuilderField from '../../../DataBuilder/field-context.mjs';
import DataBuilderFormField from '../../../DataBuilder/Field.mjs';
import { makeDataField } from '../../../DataBuilder/index.mjs';
import { DataBuilderForm } from '../../../DataBuilder/Form.mjs';
import classes from './Collections.module.css.mjs';

function LookupTab({ onSelect }) {
  const { field } = useDataBuilderField();
  if (!field) {
    return null;
  }
  function handleSelect(value) {
    onSelect?.(value);
  }
  return /* @__PURE__ */ jsx(DataBuilderDrilldownLookup, { field, onChange: handleSelect });
}
function DataBuilderDrilldownLookup({ field, onChange }) {
  const { client } = useIntegrationAppClient();
  const { integrationId } = useIntegrationAppIntegration();
  const { connectionId } = useIntegrationAppConnection();
  const lookupValue = field.value?.$lookup ?? {};
  const [
    { queryFieldsSchema, createFieldsSchema } = {
      queryFieldsSchema: null,
      createFieldsSchema: null
    },
    error,
    state
  ] = usePromise(
    () => getLookupSchemas({ field, client, connectionId, integrationId }),
    [getReferenceCollectionPathForSchema(field.schema)]
  );
  function handleLookupChange(value) {
    onChange?.({
      $lookup: {
        ...lookupValue,
        ...value
      }
    });
  }
  function handleNewRecordChange(value) {
    onChange?.({
      $lookup: {
        ...lookupValue,
        create: {
          fields: value
        }
      }
    });
  }
  function handleLeaveEmptyClicked() {
    handleLookupChange({
      createIfNotFound: false
    });
  }
  function handleCreateNewClicked() {
    handleLookupChange({
      createIfNotFound: true
    });
  }
  function makeNewRecordField() {
    return makeSubField(field, {
      valueLocator: `${field.valueLocator}.$lookup.create.fields`,
      schema: schemaWithTitle(createFieldsSchema, "Fields to Create"),
      isInSchema: true,
      value: lookupValue.create?.fields,
      iconUri: field.iconUri,
      level: 1
    });
  }
  if (error) {
    return /* @__PURE__ */ jsxs("p", { children: [
      "Error: ",
      error.toString()
    ] });
  }
  if (state === "pending") {
    return /* @__PURE__ */ jsx("p", { children: "Loading..." });
  }
  if (!queryFieldsSchema) {
    return /* @__PURE__ */ jsx("p", { children: "This collection does not support lookup." });
  }
  const dataBuilderField = makeDataField({
    value: lookupValue.query,
    schema: schemaWithTitle(queryFieldsSchema, "Query fields"),
    variablesSchema: field.variablesSchema
  });
  return /* @__PURE__ */ jsxs(DropdownUI.Panel, { className: classes.wrapper, children: [
    /* @__PURE__ */ jsx(DropdownUI.Panel, { withPaddings: true, children: /* @__PURE__ */ jsx(
      DataBuilderForm,
      {
        showNullValueFields: true,
        field: dataBuilderField,
        onChange: (query) => handleLookupChange({ query })
      }
    ) }),
    createFieldsSchema && /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsxs(
        DropdownUI.Panel,
        {
          withPaddings: true,
          className: classes.createFieldSchemaForm,
          children: [
            /* @__PURE__ */ jsx("strong", { children: "If nothing found" }),
            /* @__PURE__ */ jsxs("label", { children: [
              /* @__PURE__ */ jsx(
                Radiobutton,
                {
                  checked: !lookupValue.createIfNotFound,
                  onChange: () => handleLeaveEmptyClicked(),
                  inline: true
                }
              ),
              " ",
              "Leave empty"
            ] }),
            /* @__PURE__ */ jsxs("label", { children: [
              /* @__PURE__ */ jsx(
                Radiobutton,
                {
                  checked: !!lookupValue.createIfNotFound,
                  onChange: () => handleCreateNewClicked(),
                  inline: true
                }
              ),
              " ",
              "Create new"
            ] })
          ]
        }
      ),
      lookupValue.createIfNotFound && /* @__PURE__ */ jsx(
        DropdownUI.Panel,
        {
          withPaddings: true,
          className: classes.createFieldSchema,
          children: /* @__PURE__ */ jsx(
            DataBuilderFormField,
            {
              field: makeNewRecordField(),
              onChange: handleNewRecordChange
            }
          )
        }
      )
    ] })
  ] });
}
async function getLookupSchemas({
  field,
  client,
  connectionId,
  integrationId
}) {
  const referencePath = getReferenceCollectionPathForSchema(field?.schema);
  const referenceUdm = field.schema?.referenceUdm;
  if (referencePath && field.schema) {
    const collection = await getLookupCollection({
      schema: field.schema,
      client,
      connectionId,
      integrationId
    });
    if (!collection) {
      return {};
    }
    const queryFieldsSchema = pickFieldsFromSchema(
      collection?.fieldsSchema,
      collection?.match?.fields ?? collection?.find?.queryFields
    );
    delete queryFieldsSchema.required;
    const createFieldsSchema = getDataCollectionCreateFields(collection);
    return {
      queryFieldsSchema,
      createFieldsSchema
    };
  } else if (referenceUdm) {
    const udmSpec = UNIFIED_DATA_MODELS[referenceUdm];
    if (udmSpec) {
      return {
        queryFieldsSchema: udmSpec.match?.fields ? pickFieldsFromSchema(udmSpec.fieldsSchema, udmSpec.match.fields) : void 0,
        createFieldsSchema: udmSpec.create?.fields ? pickFieldsFromSchema(udmSpec.fieldsSchema, udmSpec.create.fields) : udmSpec.fieldsSchema
      };
    } else {
      return {};
    }
  } else {
    return {};
  }
}
async function getLookupCollection({
  schema,
  client,
  connectionId,
  integrationId
}) {
  if (!schema) {
    return void 0;
  }
  const { key, parameters } = getReferenceCollectionPointerForSchema(schema) ?? {};
  if (!key) {
    return void 0;
  }
  if (!connectionId && !integrationId) {
    return void 0;
  }
  let result = void 0;
  try {
    if (connectionId) {
      result = await client.connection(connectionId).dataCollection(key, parameters).get();
    } else if (integrationId && key) {
      result = await client.integration(integrationId).getDataCollection(key);
    }
  } catch (e) {
    console.error(`fetching collection '${key}' returns error`, e);
  }
  return result;
}

export { LookupTab, getLookupCollection };
