"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.1
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import { isFormula } from '@integration-app/sdk';
import DataBuilderFormNestedArrayItems from './ArrayItems.mjs';
import DataBuilderFormNestedObjectFields from './ObjectFields.mjs';

function DataBuilderFormNestedNoSchema({
  field,
  onChange
}) {
  if (Array.isArray(field.value)) {
    return /* @__PURE__ */ jsx(DataBuilderFormNestedArrayItems, { field, onChange });
  } else if (typeof field.value === "object" && field.value !== null && !isFormula(field.value)) {
    return /* @__PURE__ */ jsx(DataBuilderFormNestedObjectFields, { field, onChange });
  } else {
    return null;
  }
}

export { DataBuilderFormNestedNoSchema as default };
