/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.1
 */
'use client';

import styleInject from 'style-inject';

var css_248z = ".Collections-module_wrapper__mUGMk {\n    max-height: 100%;\n    overflow: scroll\n}\n\n.Collections-module_createFieldSchemaForm__F3r63 {\n    --tw-bg-opacity: 1;\n    background-color: rgb(238 238 238 / var(--tw-bg-opacity));\n    display: flex;\n    flex-direction: column;\n    gap: 0.25rem\n}\n\n.Collections-module_createFieldSchemaForm__F3r63 label {\n    display: block;\n    cursor: pointer;\n    width: -moz-fit-content;\n    width: fit-content\n}\n\n.Collections-module_createFieldSchemaForm__F3r63 h3,\n.Collections-module_createFieldSchema__0uU9i h3 {\n    font-size: 0.875rem;\n    line-height: 1.4;\n    font-weight: 700;\n    margin-bottom: 0.25rem\n}\n";
var classes = {"wrapper":"Collections-module_wrapper__mUGMk","createFieldSchemaForm":"Collections-module_createFieldSchemaForm__F3r63","createFieldSchema":"Collections-module_createFieldSchema__0uU9i"};
styleInject(css_248z);

export { classes as default };
