"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.1
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import { Node, mergeAttributes } from '@tiptap/core';
import { ReactNodeViewRenderer, NodeViewWrapper } from '@tiptap/react';
import { useCallback } from 'react';
import { ComboBox } from '../../ComboBox/index.mjs';
import { useComboBox } from '../../ComboBox/context/combobox-context.mjs';
import classes from '../inputs.module.css.mjs';

const TAG_EXTENSION_NAME = "tagComponent";
const TAG_EXTENSION_TAG = "tag-component";
const TagComponent = (props) => {
  const { value, autoOpen } = props.node.attrs;
  const handleChange = (value2) => {
    if (value2 === void 0) {
      props.deleteNode();
    } else {
      props.updateAttributes({
        value: value2
      });
    }
  };
  return /* @__PURE__ */ jsx(NodeViewWrapper, { as: "span", className: classes.tagWrapper, children: /* @__PURE__ */ jsx(Tag, { value, autoOpen, onChange: handleChange }) });
};
function Tag({
  value,
  autoOpen,
  onChange
}) {
  const { variablesSchema } = useComboBox();
  const handleValueChange = useCallback(
    (value2) => {
      onChange(value2);
    },
    [onChange]
  );
  return /* @__PURE__ */ jsx(
    ComboBox,
    {
      className: classes.innerTag,
      value,
      schema: { type: "string" },
      variablesOnly: true,
      variablesSchema,
      onChange: handleValueChange,
      autoOpen
    }
  );
}
var TagComponent$1 = Node.create({
  name: TAG_EXTENSION_NAME,
  group: "inline",
  inline: true,
  atom: true,
  addAttributes() {
    return {
      id: {
        default: null
      },
      value: {
        default: null
      },
      autoOpen: {
        default: false
      }
    };
  },
  parseHTML() {
    return [
      {
        tag: TAG_EXTENSION_TAG
      }
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return [TAG_EXTENSION_TAG, mergeAttributes(HTMLAttributes)];
  },
  addNodeView() {
    return ReactNodeViewRenderer(TagComponent, { contentDOMElementTag: "span" });
  }
});
function getComponentTagNode(id, value, { autoOpen } = {}) {
  return {
    type: TAG_EXTENSION_NAME,
    attrs: {
      id,
      value,
      autoOpen
    }
  };
}

export { TAG_EXTENSION_NAME, TAG_EXTENSION_TAG, TagComponent$1 as default, getComponentTagNode };
