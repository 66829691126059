"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.1
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import classes from './styles.module.css.mjs';
import { DataBuilderProvider } from './data-builder-context.mjs';
import DataBuilderCondition from './Condition/index.mjs';
import { makeDataField } from './index.mjs';

function DataBuilderFilter({
  value,
  variablesSchema,
  onChange
}) {
  const field = makeDataField({
    value,
    variablesSchema
  });
  return /* @__PURE__ */ jsx(DataBuilderProvider, { topLevelField: field, children: /* @__PURE__ */ jsx("div", { className: classes.wrapper, children: /* @__PURE__ */ jsx(DataBuilderCondition, { field, onChange }) }) });
}

export { DataBuilderFilter };
