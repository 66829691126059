"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.1
 */
'use client';

import { jsx, jsxs, Fragment } from 'react/jsx-runtime';
import { useState, Fragment as Fragment$1 } from 'react';
import { Truncate } from '../../../Truncate.mjs';
import { isFieldVisible, isObjectAllowedAdditionalProperties, DEFAULT_FIELD_KEY } from '../../data-field.mjs';
import useEventStopPropagation from '../../../../hooks/useEventStopPropagation.mjs';
import { SvgIcon, SvgIconType } from '../../../SvgIcon/index.mjs';
import useDataBuilderField from '../../field-context.mjs';
import { useDataBuilderFieldCanUseVarFromVariables } from '../../hooks.mjs';
import { AddingFieldSelector } from './AddingFieldSelector.mjs';
import '../../../DataBuilderUI/index.mjs';
import { FieldControlPanel } from '../../../DataBuilderUI/FieldControlPanel/index.mjs';

function ControlPanel({
  field,
  additionalFields = [],
  addFieldLabel = "Add Field",
  onAdd
}) {
  const fieldIsVisible = isFieldVisible(field);
  const [showFieldSelector, setShowFieldSelector] = useState(false);
  const fieldCanUseVarFromVariables = useDataBuilderFieldCanUseVarFromVariables();
  const showAddFieldButtonGroup = isObjectAllowedAdditionalProperties(field) || additionalFields.length > 0;
  const showUseFromVariablesButtonGroup = fieldIsVisible && fieldCanUseVarFromVariables;
  if (showFieldSelector) {
    return /* @__PURE__ */ jsx(
      AddingFieldSelector,
      {
        parentField: field,
        fieldsToAdd: additionalFields,
        onChange: onAdd,
        onClose: () => setShowFieldSelector(false)
      }
    );
  }
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    showAddFieldButtonGroup && /* @__PURE__ */ jsx(
      GroupAddField,
      {
        additionalFields,
        addFieldLabel,
        onAdd,
        onShowFieldSelector: () => setShowFieldSelector(true)
      }
    ),
    showUseFromVariablesButtonGroup && /* @__PURE__ */ jsx(GroupUseFromVariables, { labelOr: showAddFieldButtonGroup })
  ] });
}
function GroupAddField({
  additionalFields = [],
  addFieldLabel = "Add Field",
  onAdd,
  onShowFieldSelector
}) {
  const handleAddNewFieldClick = useEventStopPropagation(() => {
    if (additionalFields.length > 0) {
      onShowFieldSelector();
    } else {
      onAdd(DEFAULT_FIELD_KEY);
    }
  });
  return /* @__PURE__ */ jsx(FieldControlPanel.ButtonGroup, { children: /* @__PURE__ */ jsxs(
    FieldControlPanel.Button,
    {
      onClick: handleAddNewFieldClick,
      "aria-label": addFieldLabel,
      children: [
        /* @__PURE__ */ jsx(SvgIcon, { type: SvgIconType.Add }),
        /* @__PURE__ */ jsx(Truncate, { mode: "middle", children: addFieldLabel })
      ]
    }
  ) });
}
function GroupUseFromVariables({ labelOr }) {
  const { setAutoOpen, localSchema } = useDataBuilderField();
  const localSchemaType = localSchema?.type;
  function handleUseVarsFromVariables() {
    setAutoOpen(true);
  }
  if (!["array", "object"].includes(localSchemaType)) {
    return null;
  }
  const title = getButtonUseFromVariablesTitle(localSchemaType);
  const Wrapper = labelOr ? FieldControlPanel : Fragment$1;
  return /* @__PURE__ */ jsxs(Wrapper, { noWrap: true, children: [
    labelOr && /* @__PURE__ */ jsx(FieldControlPanel.Text, { children: "or" }),
    /* @__PURE__ */ jsx(FieldControlPanel.ButtonGroup, { children: /* @__PURE__ */ jsx(FieldControlPanel.Button, { onClick: handleUseVarsFromVariables, children: /* @__PURE__ */ jsx(Truncate, { mode: "end", children: title }) }) })
  ] });
}
function getButtonUseFromVariablesTitle(schemaType) {
  switch (schemaType) {
    case "array":
      return "Use list from variables";
    case "object":
      return "Use object from variables";
    default:
      return "Use from variables";
  }
}

export { ControlPanel };
