"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.1
 */
'use client';

import { jsx, jsxs } from 'react/jsx-runtime';
import { ComboBox } from '../../ComboBox/index.mjs';
import { ComboBoxTag } from '../../ComboBoxElements/ComboBoxTag.mjs';
import { TagPlaceholder } from '../../Tag/TagPlaceholder.mjs';
import { Formula, Variable, VariableSvgIcon } from '../../Formula/index.mjs';
import { getValueSpecFromSchema } from '../../ComboBox/value-spec.mjs';
import { SvgIconType } from '../../SvgIcon/index.mjs';
import { forwardRef } from 'react';
import { locatorToField, getFullNameForLocator } from '@integration-app/sdk';
import useDataBuilderField from '../field-context.mjs';
import useDataBuilder from '../data-builder-context.mjs';
import { ConstantOptionFactory } from '../../ComboBox/options-factories/constant.mjs';
import { Row, Cell, Title } from '../../DataBuilderUI/index.mjs';

const CopyFormula = forwardRef(
  (props, ref) => {
    const { topLevelField } = useDataBuilder();
    const { field } = useDataBuilderField();
    if (!field || !topLevelField) {
      return /* @__PURE__ */ jsx("span", { children: "Copy formula cannot be used here." });
    }
    return /* @__PURE__ */ jsx(Formula, { ref, onClick: props.onClick, children: /* @__PURE__ */ jsxs(Variable, { children: [
      /* @__PURE__ */ jsx(VariableSvgIcon, { type: SvgIconType.Function }),
      " Copy Value from Another Field"
    ] }) });
  }
);
CopyFormula.displayName = "CopyFormula";
function CopyFormulaDropdown(props) {
  const { onChange, formula } = props;
  const { topLevelField } = useDataBuilder();
  const { field } = useDataBuilderField();
  if (!field || !topLevelField) {
    return /* @__PURE__ */ jsx("span", { children: "Copy formula cannot be used here." });
  }
  function handleLocatorChange(value) {
    onChange?.({
      $copy: value
    });
  }
  const valueSchema = field.schema;
  const valueSpec = {
    ...getValueSpecFromSchema(valueSchema),
    allowCustom: false
  };
  const locators = Object.keys(topLevelField.schema?.properties ?? {}).filter(
    (l) => field.valueLocator && l !== locatorToField(field.valueLocator)
  );
  const options = locators.map((l) => ({
    value: l,
    label: getFullNameForLocator(topLevelField.schema, l)
  }));
  const selectedLabel = formula.locator ? getFullNameForLocator(topLevelField.schema, formula.locator) : null;
  return /* @__PURE__ */ jsxs(Row, { children: [
    /* @__PURE__ */ jsx(Cell.Name, { children: /* @__PURE__ */ jsx(Title, { children: "Copy Value from Another Field" }) }),
    /* @__PURE__ */ jsx(Cell.Combobox, { children: /* @__PURE__ */ jsx(
      ComboBox,
      {
        value: formula.locator,
        schema: valueSchema,
        valueSpec,
        optionFactories: [new ConstantOptionFactory(options)],
        variablesSchema: topLevelField.schema,
        onChange: handleLocatorChange,
        valueComponent: /* @__PURE__ */ jsx(CopyFormulaValueComponent, { value: selectedLabel })
      }
    ) })
  ] });
}
function CopyFormulaValueComponent({ value, placeholder = "" }) {
  if (value) {
    return /* @__PURE__ */ jsx(ComboBoxTag, { children: value });
  } else {
    return /* @__PURE__ */ jsx(TagPlaceholder, { children: placeholder });
  }
}

export { CopyFormulaDropdown, CopyFormula as default };
