"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.1
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import { useState } from 'react';
import { DropdownUI } from '../../DropdownUI/index.mjs';
import { useOptionListKeyNavDispatch } from '../../../hooks/keyboard-events.mjs';
import { useComboBoxDropdownSearchContext } from '../context/combobox-dropdown-search.mjs';
import { useComboBoxDropdownContext } from '../context/combobox-dropdown-context.mjs';
import { renderTabs } from './tabs.mjs';
import { useComboBox } from '../context/combobox-context.mjs';

function SearchInput({ autoFocus }) {
  const { activeTab } = useComboBoxDropdownContext();
  const { hideSearch } = useComboBoxDropdownSearchContext();
  if (activeTab && renderTabs[activeTab]?.hideSearchInput) {
    return null;
  }
  if (hideSearch) {
    return null;
  }
  return /* @__PURE__ */ jsx(DropdownSearchComponent, { autoFocus });
}
function DropdownSearchComponent({
  autoFocus
}) {
  const { value: parentInputValue } = useComboBox();
  const [searchInputElement, setSearchInputElement] = useState(null);
  const {
    searchValue,
    setSearchValue,
    drillDownDepth,
    searchSource,
    setSearchSource
  } = useComboBoxDropdownSearchContext();
  const canUseParentInputAsPlaceholder = !searchValue && typeof parentInputValue === "string" && !!parentInputValue && drillDownDepth === 0 && searchSource === "editor";
  useOptionListKeyNavDispatch(searchInputElement);
  return /* @__PURE__ */ jsx(
    DropdownUI.Search,
    {
      ref: setSearchInputElement,
      value: searchValue,
      onChange: setSearchValue,
      autoFocus,
      placeholder: canUseParentInputAsPlaceholder ? parentInputValue : void 0,
      onFocus: () => setSearchSource("combobox")
    }
  );
}

export { DropdownSearchComponent, SearchInput };
