"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.1
 */
'use client';

import { WorkspaceElementType } from '@integration-app/sdk';
import { useElement } from '../useElement.mjs';

function useCustomer(selector) {
  const { item: customer, ...rest } = useElement(
    WorkspaceElementType.Customer,
    selector,
    (integrationApp) => selector ? integrationApp.customer(selector) : void 0
  );
  return { customer, ...rest };
}

export { useCustomer };
