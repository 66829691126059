"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.1
 */
'use client';

import { jsx, Fragment } from 'react/jsx-runtime';
import { createContext, useContext, useRef, useEffect } from 'react';
import { getRefElement } from '../../../helpers/getRefElement.mjs';

const DropdownStackContext = createContext(false);
let GLOBAL_DROPDOWN_STACK = [];
const DropdownStackContextProvider = ({
  children
}) => {
  const hasNestedDropdownsContext = useContext(DropdownStackContext);
  if (hasNestedDropdownsContext) {
    return /* @__PURE__ */ jsx(Fragment, { children });
  }
  return /* @__PURE__ */ jsx(DropdownStackContext.Provider, { value: true, children });
};
const useDropdownStackContext = (dropdownRef) => {
  const isNested = useContext(DropdownStackContext);
  const recordRef = useRef(dropdownRef);
  useEffect(() => {
    if (isNested) {
      GLOBAL_DROPDOWN_STACK.push(recordRef?.current);
    } else {
      GLOBAL_DROPDOWN_STACK = [recordRef?.current];
    }
    return () => {
      GLOBAL_DROPDOWN_STACK = GLOBAL_DROPDOWN_STACK.filter(
        (record) => record !== recordRef?.current
      );
    };
  }, []);
  function isClickInsideRefsAndChildren(event) {
    const stackStartedFromElement = GLOBAL_DROPDOWN_STACK.slice(
      GLOBAL_DROPDOWN_STACK.indexOf(recordRef?.current)
    );
    return stackStartedFromElement.some((ref) => {
      return isClickInsideRefRecord(ref, event);
    });
  }
  return { isClickInsideRefsAndChildren };
};
function isClickInsideRefRecord(record, event) {
  const element = getRefElement(record);
  return !!(element && element.contains(event.target));
}

export { DropdownStackContextProvider, useDropdownStackContext };
