"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.1
 */
'use client';

import { SvgIconType } from '../../SvgIcon/index.mjs';
import { ComboBoxTabType } from '../types.mjs';
import { OptionsTab } from './Tabs/Options.mjs';
import { VariablesTab } from './Tabs/Variables.mjs';
import { FormulasTab } from './Tabs/Formulas/index.mjs';
import { CustomValueTab } from './Tabs/CustomValue.mjs';
import { LookupOptionsTab } from './Tabs/LookupOptions/index.mjs';
import { LookupTab } from './Tabs/Lookup.mjs';

const renderTabs = {
  [ComboBoxTabType.CUSTOM]: {
    title: "Custom Value",
    showIfSingle: true,
    hideSearchInput: true
  },
  [ComboBoxTabType.VARIABLE]: {
    title: "Variable",
    icon: SvgIconType.Variable,
    showIfSingle: true
  },
  [ComboBoxTabType.OPTIONS]: {
    title: "Option"
  },
  [ComboBoxTabType.FORMULA]: {
    title: "Formula",
    showIfSingle: true,
    hideSearchInput: true
  },
  [ComboBoxTabType.LOOKUP_OPTIONS]: {
    title: "Option",
    showIfSingle: true,
    hideSearchInput: false
  },
  [ComboBoxTabType.SELECT_FROM_UDM_DYNAMICALLY]: {
    title: "Select Dynamically",
    showIfSingle: true,
    hideSearchInput: true
  },
  [ComboBoxTabType.SELECT_FROM_COLLECTION_DYNAMICALLY]: {
    title: "Select Dynamically",
    showIfSingle: true,
    hideSearchInput: true
  }
};
const TAB_COMPONENTS = {
  [ComboBoxTabType.OPTIONS]: OptionsTab,
  [ComboBoxTabType.VARIABLE]: VariablesTab,
  [ComboBoxTabType.FORMULA]: FormulasTab,
  [ComboBoxTabType.CUSTOM]: CustomValueTab,
  [ComboBoxTabType.SELECT_FROM_UDM_DYNAMICALLY]: LookupTab,
  [ComboBoxTabType.SELECT_FROM_COLLECTION_DYNAMICALLY]: LookupTab,
  [ComboBoxTabType.LOOKUP_OPTIONS]: LookupOptionsTab
  //   [TabType.OPTIONS]: OptionsTab,
  //   [TabType.VARIABLES]: VariablesTab,
  //   [TabType.FORMULAS]: FormulasTab,
  //   [TabType.OPTIONS_ENUM]: OptionsEnumTab,
  //   [TabType.OPTIONS_REF_RECORDS]: OptionsRefRecordsTab,
  //   [TabType.OPTIONS_REF_COLLECTION]: OptionsRefCollectionTab,
  //   [TabType.OPTIONS_BOOLEAN]: BooleanTab,
  //   [TabType.CUSTOM_OBJECT]: CustomObjectTab,
  //   [TabType.CUSTOM_LIST]: CustomListTab,
};

export { TAB_COMPONENTS, renderTabs };
