"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.1
 */
'use client';

import { WorkspaceElementType } from '@integration-app/sdk';
import { useElement } from '../useElement.mjs';

function useDataSourceInstance(selector) {
  const {
    item: dataSourceInstance,
    accessor,
    refresh,
    ...rest
  } = useElement(
    WorkspaceElementType.DataSourceInstance,
    selector,
    (integrationApp) => selector ? integrationApp.dataSourceInstance(selector) : void 0
  );
  async function setup() {
    await accessor?.setup();
    await refresh();
  }
  async function reset() {
    await accessor?.reset();
    await refresh();
  }
  async function openConfiguration(options) {
    return accessor?.openConfiguration(options);
  }
  async function listRecords(request) {
    return accessor?.listRecords(request);
  }
  async function findRecords(request) {
    return accessor?.findRecords(request);
  }
  async function findRecordById(id) {
    return accessor?.findRecordById(id);
  }
  async function createRecord(request) {
    return accessor?.createRecord(request);
  }
  async function updateRecord(request) {
    return accessor?.updateRecord(request);
  }
  async function deleteRecord(id) {
    return accessor?.deleteRecord(id);
  }
  async function unifiedFieldsToNative(unifiedFields) {
    return accessor?.unifiedFieldsToNative(unifiedFields);
  }
  async function getCollection() {
    return accessor?.getCollection();
  }
  return {
    dataSourceInstance,
    accessor,
    refresh,
    setup,
    reset,
    openConfiguration,
    listRecords,
    findRecords,
    findRecordById,
    createRecord,
    updateRecord,
    deleteRecord,
    unifiedFieldsToNative,
    getCollection,
    ...rest
  };
}

export { useDataSourceInstance };
