"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.1
 */
'use client';

import { jsx, jsxs, Fragment } from 'react/jsx-runtime';
import { forwardRef } from 'react';
import { DropdownUIPopper } from '../../ComboBoxElements/Popper.mjs';
import { DropdownUI } from '../../DropdownUI/index.mjs';
import { useWindowEvent } from '../../../hooks/useWindowEvent.mjs';
import { useComboBoxDropdownContext } from '../context/combobox-dropdown-context.mjs';
import { useDropdownStackContext, DropdownStackContextProvider } from '../context/combobox-dropdown-stack.mjs';
import { SearchInput } from './SearchInput.mjs';
import { ActiveTab } from './ActiveTab.mjs';
import { renderTabs } from './tabs.mjs';
import { TabIcon } from '../../DropdownUI/DropdownUIElements.mjs';

const Dropdown = forwardRef(
  ({
    referenceElement,
    topDropdownPanelComponent,
    autoFocusOnSearch,
    onSelect,
    onClose
  }, ref) => {
    const { isClickInsideRefsAndChildren } = useDropdownStackContext(
      ref
    );
    useClick((event) => {
      if (!isClickInsideRefsAndChildren(event)) {
        onClose();
      }
    });
    useWindowEvent("keydown", (e) => {
      e.key === "Escape" && onClose();
    });
    const { availableTabs } = useComboBoxDropdownContext();
    if (availableTabs === null) {
      return null;
    }
    if (availableTabs?.length < 1) {
      return /* @__PURE__ */ jsx(DropdownStackContextProvider, { children: /* @__PURE__ */ jsx(DropdownUIPopper, { referenceElement, children: /* @__PURE__ */ jsx(DropdownUI, { ref, children: /* @__PURE__ */ jsx(DropdownUI.LoadingPanel, { children: "Loading options" }) }) }) });
    }
    return /* @__PURE__ */ jsx(DropdownStackContextProvider, { children: /* @__PURE__ */ jsx(DropdownUIPopper, { referenceElement, children: /* @__PURE__ */ jsxs(DropdownUI, { ref, children: [
      /* @__PURE__ */ jsx(DropdownTabs, {}),
      topDropdownPanelComponent && /* @__PURE__ */ jsx(Fragment, { children: topDropdownPanelComponent }),
      /* @__PURE__ */ jsx(SearchInput, { autoFocus: autoFocusOnSearch }),
      /* @__PURE__ */ jsx(ActiveTab, { onSelect })
    ] }) }) });
  }
);
function DropdownTabs() {
  const { activeTab, availableTabs, setActiveTab } = useComboBoxDropdownContext();
  if (!availableTabs || availableTabs.length === 0) return null;
  if (availableTabs.length === 1 && hideTabIfSingle(availableTabs[0]))
    return null;
  return /* @__PURE__ */ jsx(DropdownUI.Tabs, { children: availableTabs.map((tab) => /* @__PURE__ */ jsx(
    Tab,
    {
      selected: activeTab === tab,
      onClick: () => setActiveTab(tab),
      title: renderTabs[tab].title,
      icon: renderTabs[tab]?.icon
    },
    tab
  )) });
}
function Tab({
  selected,
  onClick,
  title,
  icon
}) {
  return /* @__PURE__ */ jsxs(DropdownUI.Tab, { selected, onClick, children: [
    title,
    icon && /* @__PURE__ */ jsx(TabIcon, { type: icon })
  ] });
}
function hideTabIfSingle(tab) {
  return !renderTabs[tab]?.showIfSingle;
}
function useClick(cb) {
  useWindowEvent("mouseup", cb);
  useWindowEvent("touchend", cb);
}

export { Dropdown, DropdownTabs };
