"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.1
 */
'use client';

var ComboBoxTabType = /* @__PURE__ */ ((ComboBoxTabType2) => {
  ComboBoxTabType2["VARIABLE"] = "variable";
  ComboBoxTabType2["OPTIONS"] = "options";
  ComboBoxTabType2["FORMULA"] = "formula";
  ComboBoxTabType2["CUSTOM"] = "custom";
  ComboBoxTabType2["LOOKUP_OPTIONS"] = "lookup_options";
  ComboBoxTabType2["SELECT_FROM_COLLECTION_DYNAMICALLY"] = "select_from_collection_dynamically";
  ComboBoxTabType2["SELECT_FROM_UDM_DYNAMICALLY"] = "select_from_udm_dynamically";
  return ComboBoxTabType2;
})(ComboBoxTabType || {});

export { ComboBoxTabType };
