"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.1
 */
'use client';

import { filterOptionsByLabel } from './base.mjs';

class ConstantOptionFactory {
  constructor(options) {
    this.options = options;
  }
  async getOptions({ input }) {
    return this.getOptionsSync({ input });
  }
  getOptionsSync({ input }) {
    return filterOptionsByLabel(this.options, input);
  }
}

export { ConstantOptionFactory };
