"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.1
 */
'use client';

import { isCustomFieldValue } from '../../../ComboBox/options-factories/custom-field.mjs';

const TAG_MARK_END = "}";
const TAG_MARK_START = "{";
const regExpStr = `(${TAG_MARK_START}(?:[^${TAG_MARK_START}${TAG_MARK_END}\\s]*)${TAG_MARK_END})`;
const regExpTag = new RegExp(regExpStr, "gm");
function getTextNode(string) {
  return {
    type: "text",
    text: string
  };
}
function getTemplateFromTplValue(value) {
  if (typeof value === "string") {
    return value;
  }
  if (value?.$tpl) {
    return value.$tpl.template;
  }
  return "";
}
function getValuesFromTplValue(value) {
  if (value?.$tpl) {
    return value.$tpl.values;
  }
  return {};
}
function transformValueToTpl(value) {
  if (value === void 0 || value === null) {
    return "";
  }
  if (isCustomFieldValue(value)) {
    return "";
  }
  if (typeof value === "string") {
    return value;
  }
  if (typeof value === "number") {
    return String(value);
  }
  if (value?.$tpl) {
    return value;
  }
  return {
    $tpl: {
      template: "{10000000}",
      values: {
        1e7: value
      }
    }
  };
}
function transformTplToValue(template, values) {
  if (template === "" || template === void 0 || template === null) {
    return void 0;
  }
  const valuesExistInTemplate = getValuesExistInTemplate(template, values);
  const cleanedTemplate = getTemplateWithExistingTags(
    template,
    valuesExistInTemplate
  );
  if (!strContainsTagChunk(cleanedTemplate)) {
    return cleanedTemplate ? cleanedTemplate : void 0;
  }
  if (Object.keys(valuesExistInTemplate).length === 1 && isTagChunk(cleanedTemplate)) {
    return Object.values(valuesExistInTemplate)[0];
  }
  return {
    $tpl: {
      template: cleanedTemplate,
      values: valuesExistInTemplate
    }
  };
}
function splitStringToChunks(line) {
  return line.split(regExpTag).filter((l) => l !== "");
}
function isTagChunk(chunk) {
  return strContainsTagChunk(chunk) && chunk.startsWith(TAG_MARK_START) && chunk.endsWith(TAG_MARK_END);
}
function isValueEmpty(value) {
  return value === void 0 || value === null;
}
function strContainsTagChunk(str) {
  return str ? !!str.match(regExpTag) : false;
}
function getTagChunkValue(chunk) {
  return chunk.substring(1, chunk.length - 1);
}
function getValuesExistInTemplate(template, values) {
  return Object.keys(values).reduce((acc, valueId) => {
    return !isValueEmpty(values[valueId]) && template.includes(getTagString(valueId)) ? { ...acc, [valueId]: values[valueId] } : acc;
  }, {});
}
function getTemplateWithExistingTags(template, values) {
  return splitStringToChunks(template ?? "").map((string) => {
    if (isTagChunk(string) && isValueEmpty(values[getTagChunkValue(string)])) {
      return "";
    }
    return string;
  }).join("");
}
function getTagString(id) {
  return `${TAG_MARK_START}${id}${TAG_MARK_END}`;
}
function getSchemaNodesSummary(schema) {
  const content = schema?.content ?? [];
  return content.reduce(
    (acc, node) => {
      const items = node.content ?? [];
      const innerTotal = items.reduce(
        (innerAcc, item) => {
          if (item?.type === "text") {
            innerAcc.text = innerAcc.text + 1;
          } else {
            innerAcc.nonText = innerAcc.nonText + 1;
          }
          return innerAcc;
        },
        { text: 0, nonText: 0 }
      );
      acc.text = acc.text + innerTotal.text;
      acc.nonText = acc.nonText + innerTotal.nonText;
      return acc;
    },
    { text: 0, nonText: 0 }
  );
}

export { TAG_MARK_END, TAG_MARK_START, getSchemaNodesSummary, getTagChunkValue, getTagString, getTemplateFromTplValue, getTemplateWithExistingTags, getTextNode, getValuesExistInTemplate, getValuesFromTplValue, isTagChunk, regExpTag, splitStringToChunks, transformTplToValue, transformValueToTpl };
