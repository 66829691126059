"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.1
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import { SvgIcon, SvgIconType } from '../SvgIcon/index.mjs';
import useEventStopPropagation from '../../hooks/useEventStopPropagation.mjs';
import { ActionBar } from '../DataBuilderUI/index.mjs';

function FieldDelete({ onClick }) {
  if (!onClick) {
    return null;
  }
  return /* @__PURE__ */ jsx(ActionBar, { children: /* @__PURE__ */ jsx(ActionBar.DeleteButton, { onClick: useEventStopPropagation(onClick), children: /* @__PURE__ */ jsx(SvgIcon, { type: SvgIconType.Close }) }) });
}

export { FieldDelete };
