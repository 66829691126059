/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.1
 */
'use client';

import styleInject from 'style-inject';

var css_248z = ".Truncate-module_truncatedWrapper__VsESm {\n  display: inline-flex;\n  overflow: hidden;\n  max-width: 100%;\n}\n.Truncate-module_truncated__-Kh-M {\n  width: -moz-fit-content;\n  width: fit-content;\n  display: inline-flex;\n  flex-direction: row;\n  flex-wrap: nowrap;\n  max-width: 100%;\n}\n.Truncate-module_truncatedEnd__UYkMz {\n  display: block;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n}\n.Truncate-module_startSegment__hJ-Hm {\n  display: inline-block;\n  flex-grow: 1;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n}\n.Truncate-module_endSegment__7ywT7 {\n  display: inline-block;\n  flex-shrink: 0;\n  flex-grow: 0;\n}\n";
var classes = {"truncatedWrapper":"Truncate-module_truncatedWrapper__VsESm","truncated":"Truncate-module_truncated__-Kh-M","truncatedEnd":"Truncate-module_truncatedEnd__UYkMz","startSegment":"Truncate-module_startSegment__hJ-Hm","endSegment":"Truncate-module_endSegment__7ywT7"};
styleInject(css_248z);

export { classes as default };
