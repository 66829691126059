/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.1
 */
'use client';

import styleInject from 'style-inject';

var css_248z = ".FieldListWrapper-module_container__gog9U {\n\n  padding-bottom: 0.25rem;\n\n  padding-top: 0.75rem;\n\n  cursor: pointer\n}\n.FieldListWrapper-module_container__collapsed__9x5Po {\n\n  margin-top: -2.5rem;\n\n  padding-top: 2.5rem;\n\n  position: relative;\n\n  z-index: 10;\n\n  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));\n\n  --tw-gradient-from: transparent var(--tw-gradient-from-position);\n\n  --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position);\n\n  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);\n\n  --tw-gradient-to: #fff var(--tw-gradient-to-position);\n\n  --tw-gradient-stops: var(--tw-gradient-from),\n    var(--tw-gradient-to, transparent) 40%\n}\n.FieldListWrapper-module_button__fkFJa {\n\n  padding-left: 0px;\n\n  padding-right: 0px;\n\n  padding-top: 0.125rem;\n\n  padding-bottom: 0.125rem;\n\n  border-bottom-width: 1px;\n\n  border-color: transparent;\n\n  display: flex;\n\n  flex-direction: row;\n\n  align-items: center;\n\n  gap: 0.25rem;\n\n  --tw-text-opacity: 1;\n\n  color: rgb(13 121 194 / var(--tw-text-opacity))\n}\n.FieldListWrapper-module_button__fkFJa svg {\n\n  height: 1rem;\n\n  width: 1rem\n}\n.FieldListWrapper-module_button__fkFJa:hover,\n.FieldListWrapper-module_container__gog9U:hover button {\n\n  border-style: dashed;\n\n  --tw-border-opacity: 1;\n\n  border-color: rgb(13 121 194 / var(--tw-border-opacity))\n}\n";
var classes = {"container":"FieldListWrapper-module_container__gog9U","container__collapsed":"FieldListWrapper-module_container__collapsed__9x5Po","button":"FieldListWrapper-module_button__fkFJa"};
styleInject(css_248z);

export { classes as default };
