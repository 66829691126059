/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.1
 */
'use client';

import styleInject from 'style-inject';

var css_248z = "input.SearchInput-module_uikit-searchInput__7ah3R {\n  padding-left: 2.25rem;\n  background-repeat: no-repeat;\n  background-size: 1.25rem;\n  background-position: 0.625rem 0.45rem;\n  background-image: url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none'%3E%3Cpath stroke='%23AAA' stroke-linecap='round' stroke-linejoin='round' d='M2.5 8.333a5.834 5.834 0 1 0 11.667 0 5.834 5.834 0 0 0-11.667 0ZM17.5 17.5l-5-5'/%3E%3C/svg%3E\");\n}\n\ninput.SearchInput-module_uikit-searchInput__7ah3R:hover:not(:disabled,:focus) {\n    /* @apply stroke-current text-neutral05; */\n    background-image: url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none'%3E%3Cpath stroke='%23696969' stroke-linecap='round' stroke-linejoin='round' d='M2.5 8.333a5.834 5.834 0 1 0 11.667 0 5.834 5.834 0 0 0-11.667 0ZM17.5 17.5l-5-5'/%3E%3C/svg%3E\");\n  }\n\ninput.SearchInput-module_uikit-searchInput__7ah3R:focus {\n    /* @apply stroke-current text-black; */\n    background-image: url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none'%3E%3Cpath stroke='%23000' stroke-linecap='round' stroke-linejoin='round' d='M2.5 8.333a5.834 5.834 0 1 0 11.667 0 5.834 5.834 0 0 0-11.667 0ZM17.5 17.5l-5-5'/%3E%3C/svg%3E\");\n  }\n\ninput.SearchInput-module_uikit-searchInput__7ah3R::-webkit-search-decoration,input.SearchInput-module_uikit-searchInput__7ah3R::-webkit-search-cancel-button,input.SearchInput-module_uikit-searchInput__7ah3R::-webkit-search-results-button,input.SearchInput-module_uikit-searchInput__7ah3R::-webkit-search-results-decoration {\n    display: none !important;\n  }\n";
var classes = {"uikit-searchInput":"SearchInput-module_uikit-searchInput__7ah3R"};
styleInject(css_248z);

export { classes as default };
