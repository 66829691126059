"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.1
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import { Tooltip } from '../Tooltip/index.mjs';
import classes from './styles.module.css.mjs';

function FieldDescription({ text }) {
  if (!text) return null;
  return /* @__PURE__ */ jsx(
    Tooltip,
    {
      tooltip: text,
      offset: [0, 4],
      placement: "bottom-start",
      className: classes.fieldName_descriptionTooltip,
      children: /* @__PURE__ */ jsx("span", { className: classes.fieldName_description, children: "?" })
    }
  );
}

export { FieldDescription };
