"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.1
 */
'use client';

import { jsx, jsxs, Fragment } from 'react/jsx-runtime';
import { forwardRef } from 'react';
import { schemaWithTitle, buildDataSchema } from '@integration-app/sdk';
import { Formula, Variable, VariableSvgIcon } from '../../Formula/index.mjs';
import { SvgIconType } from '../../SvgIcon/index.mjs';
import { makeSubField } from '../data-field.mjs';
import DataBuilderFormField from '../Field.mjs';
import useDataBuilderField from '../field-context.mjs';

const IterateFormula = forwardRef(
  (props, ref) => {
    return /* @__PURE__ */ jsx(Formula, { ref, onClick: props.onClick, children: /* @__PURE__ */ jsxs(Variable, { children: [
      /* @__PURE__ */ jsx(VariableSvgIcon, { type: SvgIconType.Function }),
      " Iterate over"
    ] }) });
  }
);
IterateFormula.displayName = "IterateFormula";
function IteratedFormulaDropdown({
  onChange,
  formula
}) {
  const { field } = useDataBuilderField();
  const fieldWithFormulaValues = makeSubField(field, {
    value: {
      $iterate: {
        source: formula.args?.source,
        item: formula.args?.item
      }
    }
  });
  function handleSourceChange(newValue) {
    onChange?.({
      $iterate: {
        ...formula.args ?? {},
        source: newValue
      }
    });
  }
  function handleItemChange(newItem) {
    onChange?.({
      $iterate: {
        ...formula.args ?? {},
        item: newItem
      }
    });
  }
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(
      IteratedFormulaNestedSource,
      {
        field: fieldWithFormulaValues,
        onChange: handleSourceChange
      }
    ),
    /* @__PURE__ */ jsx(
      IteratedFormulaNestedItem,
      {
        field: fieldWithFormulaValues,
        onChange: handleItemChange
      }
    )
  ] });
}
function IteratedFormulaNestedSource({ field, onChange }) {
  const sourceSchema = getIteratedFormulaSourceSchema(field);
  const itemField = makeSubField(field, {
    isInSchema: true,
    valueLocator: `${field.valueLocator}.$iterate.source`,
    value: field.value?.$iterate?.source,
    schema: schemaWithTitle(sourceSchema, "Source"),
    level: 1
  });
  return /* @__PURE__ */ jsx(DataBuilderFormField, { field: itemField, onChange });
}
function IteratedFormulaNestedItem({ field, onChange }) {
  const item = field.value?.$iterate?.item;
  const sourceSchema = getIteratedFormulaSourceSchema(field);
  const itemsSchema = field.schema?.items ?? {};
  const itemField = makeSubField(field, {
    isInSchema: true,
    valueLocator: `${field.valueLocator}.$iterate.item`,
    value: item,
    schema: schemaWithTitle(itemsSchema, "Item"),
    level: 1,
    variablesSchema: {
      type: "object",
      properties: {
        item: sourceSchema?.items,
        index: { type: "number" },
        parent: field.variablesSchema
      }
    }
  });
  return /* @__PURE__ */ jsx(DataBuilderFormField, { field: itemField, onChange });
}
function getIteratedFormulaSourceSchema(field) {
  return buildDataSchema(field.value?.$iterate?.source, field.variablesSchema) ?? {
    type: "array"
  };
}

export { IteratedFormulaDropdown, IterateFormula as default };
