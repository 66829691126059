"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.1
 */
'use client';

import { jsxs, Fragment, jsx } from 'react/jsx-runtime';
import { isFormula } from '@integration-app/sdk';
import useDataBuilderField from './field-context.mjs';
import DataBuilderFormNestedArrayItems from './Nested/ArrayItems.mjs';
import DataBuilderFormNestedObjectFields from './Nested/ObjectFields.mjs';
import DataBuilderFormNestedNoSchema from './Nested/NoSchema.mjs';

function DataBuilderFormNested({
  field,
  onChange
}) {
  const { localSchema } = useDataBuilderField();
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    !localSchema?.type && /* @__PURE__ */ jsx(DataBuilderFormNestedNoSchema, { field, onChange }),
    localSchema?.type == "object" && !isFormula(field.value) && /* @__PURE__ */ jsx(DataBuilderFormNestedObjectFields, { field, onChange }),
    localSchema?.type == "array" && /* @__PURE__ */ jsx(DataBuilderFormNestedArrayItems, { field, onChange })
  ] });
}

export { DataBuilderFormNested as default };
