"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.1
 */
'use client';

import { jsxs, jsx } from 'react/jsx-runtime';
import { Switch } from '@headlessui/react';
import clsx from 'clsx';
import { SvgIcon, SvgIconType } from '../SvgIcon/index.mjs';
import classes from './Toggle.module.css.mjs';

function Toggle({ children, ...props }) {
  function toggleChange() {
    props?.onChange?.(props?.checked);
  }
  function handleChange(checked2) {
    props?.onChange?.(checked2);
  }
  if (children) {
    return /* @__PURE__ */ jsxs("label", { className: classes.labelWrapper, children: [
      /* @__PURE__ */ jsx(Toggle, { ...props }),
      /* @__PURE__ */ jsx("span", { onClick: toggleChange, children })
    ] });
  }
  const { checked, disabled, loading, onChange, className, ...restProps } = props;
  return /* @__PURE__ */ jsx(
    Switch,
    {
      checked,
      onChange: handleChange,
      className: clsx(
        classes.wrapper,
        className,
        checked && !disabled && classes.wrapper__on,
        disabled && classes.wrapper__disabled
      ),
      ...restProps,
      children: /* @__PURE__ */ jsx(
        "span",
        {
          className: clsx(
            classes.circle,
            checked ? classes.circle__on : classes.circle__off,
            loading && classes.circle__loading
          ),
          children: loading && /* @__PURE__ */ jsx(SvgIcon, { type: SvgIconType.Loader, className: classes.loader })
        }
      )
    }
  );
}

export { Toggle };
