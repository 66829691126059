"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.1
 */
'use client';

import { jsx, jsxs, Fragment } from 'react/jsx-runtime';
import { getFormula } from '@integration-app/sdk';
import { useState, useEffect } from 'react';
import { getFormulas } from '../../../../DataBuilder/Formula/index.mjs';
import { DropdownUI } from '../../../../DropdownUI/index.mjs';
import { useComboBox } from '../../../context/combobox-context.mjs';
import { OptionsList } from '../../OptionsList.mjs';
import { DropdownSearchComponent } from '../../SearchInput.mjs';
import { PanelHeader, PanelHeaderButton } from '../LookupOptions/UiComponents.mjs';
import { Button } from '../../../../Button/Button.mjs';
import classes from './Formulas.module.css.mjs';

const formulas = getFormulas();
function FormulasTab({ onSelect }) {
  const { value } = useComboBox();
  const [userForcedSelectFormula, setUserForcedSelectFormula] = useState(false);
  const formula = getFormula(value);
  const formulaSpec = formula?.type && formulas[formula?.type];
  const formulaDropdownComponentExists = !!formulaSpec?.DropdownComponent;
  function handleOnSelect(value2) {
    onSelect(value2);
    setUserForcedSelectFormula(false);
  }
  if (value === null || userForcedSelectFormula || !formulaDropdownComponentExists) {
    return /* @__PURE__ */ jsx(DropdownPanelFormulasList, { onSelect: handleOnSelect });
  } else {
    return /* @__PURE__ */ jsx(
      DropdownPanelFormulaConfig,
      {
        onBackClick: () => setUserForcedSelectFormula(true),
        onChange: onSelect
      }
    );
  }
}
function DropdownPanelFormulasList({
  onSelect
}) {
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(DropdownSearchComponent, {}),
    /* @__PURE__ */ jsx(DropdownUI.Panel, { children: /* @__PURE__ */ jsx(OptionsList, { onSelect }) })
  ] });
}
function DropdownPanelFormulaConfig({
  onChange,
  onBackClick
}) {
  const { value, schema, variablesSchema } = useComboBox();
  const [localValue, setLocalValue] = useState(value);
  useEffect(() => {
    setLocalValue(value);
  }, [JSON.stringify(value)]);
  const formula = getFormula(localValue);
  const formulaSpec = formula?.type && formulas[formula?.type];
  function handleOnChange(newValue) {
    setLocalValue(newValue);
  }
  function handleOnSaveAndClose() {
    onChange(localValue);
  }
  if (!formulaSpec?.DropdownComponent) {
    return null;
  }
  const FormulaInDropdownPanelComponent = formulaSpec?.DropdownComponent;
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(DropdownUI.Panel, { className: classes.formulaConfig__header, children: /* @__PURE__ */ jsxs(PanelHeader, { children: [
      formulaSpec?.name ?? "Formula",
      /* @__PURE__ */ jsx(PanelHeaderButton, { onClick: onBackClick, children: "Back" })
    ] }) }),
    /* @__PURE__ */ jsx(DropdownUI.Panel, { className: classes.formulaConfig__panel, children: /* @__PURE__ */ jsx("div", { className: classes.formulaConfig__panelContent, children: /* @__PURE__ */ jsx(
      FormulaInDropdownPanelComponent,
      {
        formula,
        schema,
        variablesSchema,
        onChange: handleOnChange
      }
    ) }) }),
    /* @__PURE__ */ jsx(DropdownUI.Panel, { className: classes.formulaConfig__footer, children: /* @__PURE__ */ jsx(
      Button,
      {
        size: "small",
        variant: "secondary",
        onClick: handleOnSaveAndClose,
        children: "Save & Close"
      }
    ) })
  ] });
}

export { FormulasTab };
