"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.1
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import { useState, useMemo, useEffect } from 'react';
import { usePopper } from 'react-popper';
import { Portal } from '../Portal/index.mjs';
import { useDebounce } from 'usehooks-ts';
import useResizeObserver from '../../hooks/useResizeObserver.mjs';
import { useDeepMemo } from '../../hooks/useDeepEffect.mjs';
import classes from './Popper.module.css.mjs';
import { getRefElement } from '../../helpers/getRefElement.mjs';

function DropdownUIPopper({
  referenceElement,
  children
}) {
  const [popperElement, setPopperElement] = useState();
  const popperOptions = usePopperJsOptions();
  const safeReferenceElement = getRefElement(referenceElement);
  const {
    styles,
    attributes,
    update: updatePopover
  } = usePopper(safeReferenceElement, popperElement, popperOptions);
  useDebouncedCallbackOnResize(safeReferenceElement, updatePopover);
  const popperStyles = useDeepMemo(() => {
    return styles.popper;
  }, [styles.popper]);
  const popperAttributes = useDeepMemo(
    () => attributes.popper,
    [attributes.popper]
  );
  return /* @__PURE__ */ jsx(Portal, { children: /* @__PURE__ */ jsx(
    "div",
    {
      "data-combobox": true,
      role: "dialog",
      ref: setPopperElement,
      style: popperStyles,
      ...popperAttributes,
      className: classes.popper,
      children: popperElement && children
    }
  ) });
}
function usePopperJsOptions() {
  return useMemo(() => {
    const modifiers = [];
    modifiers.push({
      name: "setPlacement",
      enabled: true,
      phase: "beforeRead",
      requires: ["flip"],
      fn({ state }) {
        const { offsetToTop, offsetToBottom } = getOffsetsFromState(state);
        const { placement, fallbackPlacements } = getPopperPlacement(
          state,
          offsetToTop,
          offsetToBottom
        );
        state.placement = placement;
        state.options.modifiers.find(
          (x) => x.name === "flip"
        ).options.fallbackPlacements = fallbackPlacements;
      }
    });
    modifiers.push({
      name: "applyMaxHeight",
      enabled: true,
      phase: "beforeRead",
      fn({ state }) {
        const { offsetToTop, offsetToBottom } = getOffsetsFromState(state);
        const additionalOffset = state.placement.includes("top") ? 20 : 0;
        const maxHeight = getMinHeight(offsetToTop, offsetToBottom) - additionalOffset;
        state.styles.popper = {
          ...state.styles.popper,
          maxHeight: `${maxHeight}px`
        };
      }
    });
    modifiers.push({
      name: "offset",
      options: {
        offset: [0, 4]
      }
    });
    modifiers.push({
      name: "flip",
      options: {
        fallbackPlacements: [
          "bottom-start",
          "bottom-end",
          "top-start",
          "top-end"
        ],
        flipVariations: true
      }
    });
    return {
      placement: "bottom-start",
      modifiers
    };
  }, []);
}
function useDebouncedCallbackOnResize(target, callback) {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const debouncedWidth = useDebounce(width, 100);
  const debouncedHeight = useDebounce(height, 100);
  useEffect(() => {
    void callback?.();
  }, [debouncedWidth, debouncedHeight, callback]);
  useResizeObserver(target, ({ resized, size }) => {
    if (resized.width) {
      setWidth(size.width);
    }
    if (resized.height) {
      setHeight(size.height);
    }
  });
}
function getOffsetsFromState(state) {
  const additionalPadding = 20;
  const { y: elPositionYRelatedToWindow, height: elHeight } = state.rects.reference;
  const visualViewport = state.scrollParents.reference.find(
    (x) => x instanceof VisualViewport
  );
  const viewportTopOffset = visualViewport?.pageTop ?? 0;
  const viewportHeight = visualViewport?.height || window.innerHeight;
  const topOffsetRelatedToViewport = elPositionYRelatedToWindow - viewportTopOffset;
  const bottomOffsetRelatedToViewport = viewportHeight - topOffsetRelatedToViewport - elHeight;
  return {
    // Calculate available space to show the popper on the top
    offsetToTop: topOffsetRelatedToViewport - additionalPadding,
    // Calculate available space to show the popper on the bottom
    offsetToBottom: bottomOffsetRelatedToViewport - additionalPadding
  };
}
function getPopperPlacement(state, offsetToTop, offsetToBottom) {
  const freeSpaceBasePlacement = offsetToTop > offsetToBottom ? "top" : "bottom";
  const [_, baseHorizontalPlacement = "start"] = state.placement.split("-");
  const placement = `${freeSpaceBasePlacement}-${baseHorizontalPlacement}`;
  const fallbackPlacements = [
    `${freeSpaceBasePlacement}-${baseHorizontalPlacement === "start" ? "end" : "start"}`
  ];
  return { placement, fallbackPlacements };
}
function getMinHeight(offsetToTop, offsetToBottom) {
  const minAvailableHeight = 300;
  return Math.max(
    Math.round(Math.max(offsetToTop, offsetToBottom)),
    minAvailableHeight
  );
}

export { DropdownUIPopper };
