"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.1
 */
'use client';

import { jsx, jsxs } from 'react/jsx-runtime';
import { SvgIcon, SvgIconType } from '../SvgIcon/index.mjs';
import { Truncate } from '../Truncate.mjs';
import classes from './DropdownUIElements.module.css.mjs';
import clsx from 'clsx';

function GenericSvgIcon({ type, ...props }) {
  return /* @__PURE__ */ jsx(SvgIcon, { type, ...props });
}
function GenericImgIcon({ src, ...props }) {
  return /* @__PURE__ */ jsx("img", { src, alt: "", ...props });
}
function TabIcon({ className, ...props }) {
  if ("type" in props && props?.type) {
    return /* @__PURE__ */ jsx(
      GenericSvgIcon,
      {
        className: clsx(classes.dropdownElement_tabIcon, className),
        ...props
      }
    );
  }
  if ("src" in props && props?.src) {
    return /* @__PURE__ */ jsx(
      GenericImgIcon,
      {
        className: clsx(classes.dropdownElement_tabIcon, className),
        ...props
      }
    );
  }
  return null;
}
function ItemIcon({ className, ...props }) {
  if ("type" in props && props?.type) {
    return /* @__PURE__ */ jsx(
      GenericSvgIcon,
      {
        className: clsx(classes.dropdownElement_itemIcon, className),
        ...props
      }
    );
  }
  if ("src" in props && props?.src) {
    return /* @__PURE__ */ jsx(
      GenericImgIcon,
      {
        className: clsx(classes.dropdownElement_itemIcon, className),
        ...props
      }
    );
  }
  return null;
}
function ItemChevronIcon({ open }) {
  return /* @__PURE__ */ jsx(
    SvgIcon,
    {
      className: classes.dropdownElement_itemChevron,
      type: open ? SvgIconType.ChevronDown : SvgIconType.ChevronRight
    }
  );
}
function ItemBackIcon() {
  return /* @__PURE__ */ jsx(
    SvgIcon,
    {
      className: classes.dropdownElement_itemIcon,
      type: SvgIconType.ChevronLeft
    }
  );
}
function ItemTitle({
  children,
  hint
}) {
  return /* @__PURE__ */ jsxs("div", { className: classes.dropdownElement_itemTitle, children: [
    /* @__PURE__ */ jsx(Truncate, { mode: "end", children }),
    hint && /* @__PURE__ */ jsx("small", { children: hint })
  ] });
}
function ItemCustomValue({
  children,
  hint
}) {
  return /* @__PURE__ */ jsxs("div", { className: classes.dropdownElement_itemCustomValue, children: [
    children,
    hint && /* @__PURE__ */ jsx("small", { children: hint })
  ] });
}

export { ItemBackIcon, ItemChevronIcon, ItemCustomValue, ItemIcon, ItemTitle, TabIcon };
