"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.1
 */
'use client';

import { jsxs, jsx } from 'react/jsx-runtime';
import clsx from 'clsx';
import { useState } from 'react';
import classes from './InlineEditable.module.css.mjs';

function InlineEditable({
  value,
  className,
  placeholder = "Enter some text",
  onChange,
  onEnter,
  onEsc,
  onFocus,
  onBlur,
  editable = true,
  required = false,
  autoFocus = false,
  ...props
}) {
  const [requiredWarning, setRequiredWarning] = useState(false);
  function handleChange(event) {
    onChange?.(event?.target?.value);
  }
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.target.blur();
      onEnter?.(value);
      return;
    }
    if (event.key === "Escape") {
      event.target.blur();
      onEsc?.(value);
      return;
    }
  };
  const handleFocus = (event) => {
    onFocus?.(event?.target?.value);
  };
  const handleBlur = (event) => {
    if (required) {
      setRequiredWarning(!event?.target?.value);
      if (!event?.target?.value) {
        return;
      }
    }
    onChange?.(event?.target?.value);
    onBlur?.(event?.target?.value);
  };
  const placeholderText = requiredWarning ? "Required field" : placeholder;
  const style = {
    "--placeholder-text": `"${placeholderText}"`
  };
  const classNames = clsx(
    classes.wrapper,
    className,
    requiredWarning && classes.wrapperRequired
  );
  return /* @__PURE__ */ jsxs("span", { className: classNames, ...props, title: value, children: [
    /* @__PURE__ */ jsx(
      "input",
      {
        className: classes.input,
        onChange: handleChange,
        value,
        placeholder: placeholderText,
        readOnly: !editable,
        required,
        autoFocus,
        onBlur: handleBlur,
        onFocus: handleFocus,
        onKeyDown: handleKeyDown
      }
    ),
    /* @__PURE__ */ jsx("span", { className: classes.span, style, children: value?.replace(/\s/g, "\xA0") })
  ] });
}

export { InlineEditable };
