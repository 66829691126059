"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.1
 */
'use client';

import { jsxs, jsx } from 'react/jsx-runtime';
import { Switch } from '@headlessui/react';
import clsx from 'clsx';
import { SvgIcon, SvgIconType } from '../SvgIcon/index.mjs';
import classes from './Toggle.module.css.mjs';

function ToggleOnOff({
  checked,
  disabled,
  loading,
  onChange,
  className,
  ...props
}) {
  function handleChange(checked2) {
    onChange?.(checked2);
  }
  return /* @__PURE__ */ jsxs(
    Switch,
    {
      checked,
      onChange: handleChange,
      className: clsx(
        classes.wrapper,
        className,
        checked && !disabled && classes.wrapper__on,
        disabled && classes.wrapper__disabled
      ),
      ...props,
      children: [
        /* @__PURE__ */ jsx(
          "span",
          {
            className: clsx(
              classes.label,
              checked ? classes.label__on : classes.label__off
            ),
            children: checked ? "on" : "off"
          }
        ),
        /* @__PURE__ */ jsx(
          "span",
          {
            className: clsx(
              classes.circle,
              checked ? classes.circle__on : classes.circle__off,
              loading && classes.circle__loading
            ),
            children: loading && /* @__PURE__ */ jsx(SvgIcon, { type: SvgIconType.Loader, className: classes.loader })
          }
        )
      ]
    }
  );
}

export { ToggleOnOff };
