/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.1
 */
'use client';

import styleInject from 'style-inject';

var css_248z = ".styles-module_valueRef__ZFG-S {\n  display: block;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap\n}\n.styles-module_valueRef__ZFG-S img,\n.styles-module_valueRef__ZFG-S svg {\n  display: inline-block;\n  vertical-align: middle;\n  margin-top: -0.1rem;\n  max-height: 1rem\n}\n.styles-module_valueRef__ZFG-S img {\n  margin-right: -0.125rem\n}\n.styles-module_valueRefFlex__G5lJH {\n  display: inline-flex;\n  justify-content: center\n}\n.styles-module_name__VBTS9 {\n  white-space: nowrap\n}\n.styles-module_valueRefTruncate__Nh-Hg {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: nowrap;\n  align-items: center\n}\n.styles-module_truncatedSegment__w48mz {\n  display: inline-block;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap\n}\n.styles-module_visibleSegment__--BWS {\n  flex-shrink: 0;\n  flex-grow: 0\n}\n.styles-module_tagIcon__uOapU {\n  margin-right: 0.25rem;\n  display: inline-block;\n  height: 1rem;\n  width: 1rem\n}\n.styles-module_caseContainer__rb67a {\n  margin-top: 0.5rem\n}\n.styles-module_caseContainer__rb67a .styles-module_field__lJDbN {\n  position: relative\n}\n.styles-module_caseContainer__rb67a .styles-module_field__lJDbN .styles-module_deleteButton__qo19I {\n  position: absolute;\n  top: 50%;\n  right: 0px;\n  margin-top: -0.625rem\n}\n";
var classes = {"valueRef":"styles-module_valueRef__ZFG-S","valueRefFlex":"styles-module_valueRefFlex__G5lJH","name":"styles-module_name__VBTS9","valueRefTruncate":"styles-module_valueRefTruncate__Nh-Hg","truncatedSegment":"styles-module_truncatedSegment__w48mz","visibleSegment":"styles-module_visibleSegment__--BWS","tagIcon":"styles-module_tagIcon__uOapU","caseContainer":"styles-module_caseContainer__rb67a","field":"styles-module_field__lJDbN","deleteButton":"styles-module_deleteButton__qo19I"};
styleInject(css_248z);

export { classes as default };
