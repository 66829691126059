"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.1
 */
'use client';

import { WorkspaceElementType } from '@integration-app/sdk';
import { useElement } from '../useElement.mjs';

function useAppEventType(selector) {
  const { item: appEventType, ...rest } = useElement(
    WorkspaceElementType.AppEventType,
    selector,
    (integrationApp) => selector ? integrationApp.appEventType(selector) : void 0
  );
  return { appEventType, ...rest };
}

export { useAppEventType };
