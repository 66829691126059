/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.1
 */
'use client';

import styleInject from 'style-inject';

var css_248z = ".UiComponents-module_panelHeader__4vfpL {\n\n    margin-left: 0px;\n\n    margin-right: 0px;\n\n    margin-top: -0.25rem;\n\n    margin-bottom: -0.25rem;\n\n    display: flex;\n\n    flex-direction: row;\n\n    align-items: center;\n\n    padding: 0px;\n\n    font-size: 0.813rem;\n\n    line-height: 1.4;\n\n    --tw-text-opacity: 1;\n\n    color: rgb(170 170 170 / var(--tw-text-opacity))\n}\n\n.UiComponents-module_panelHeader_button__-myB7 {\n\n    margin-left: auto;\n\n    margin-top: -0.125rem;\n\n    --tw-text-opacity: 1;\n\n    color: rgb(105 105 105 / var(--tw-text-opacity))\n}\n\n.UiComponents-module_parametersList__JTGpf {\n\n    display: flex;\n\n    flex-direction: row;\n\n    flex-wrap: wrap;\n\n    gap: 0.5rem\n}\n\n.UiComponents-module_parameterItem__b7sM6 {\n\n    border-radius: 0.25rem;\n\n    --tw-bg-opacity: 1;\n\n    background-color: rgb(221 221 221 / var(--tw-bg-opacity));\n\n    padding-left: 0.5rem;\n\n    padding-right: 0.5rem;\n\n    padding-top: 0.25rem;\n\n    padding-bottom: 0.25rem;\n\n    font-size: 0.813rem;\n\n    line-height: 1.4;\n\n    --tw-text-opacity: 1;\n\n    color: rgb(105 105 105 / var(--tw-text-opacity))\n}\n\n.UiComponents-module_panel__options__HXrZ8 {\n\n    display: flex;\n\n    flex-direction: column;\n\n    gap: 0.5rem\n}\n";
var classes = {"panelHeader":"UiComponents-module_panelHeader__4vfpL","panelHeader_button":"UiComponents-module_panelHeader_button__-myB7","parametersList":"UiComponents-module_parametersList__JTGpf","parameterItem":"UiComponents-module_parameterItem__b7sM6","panel__options":"UiComponents-module_panel__options__HXrZ8"};
styleInject(css_248z);

export { classes as default };
