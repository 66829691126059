/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.1
 */
'use client';

import styleInject from 'style-inject';

var css_248z = ".Popover-module_popoverWrapper__EKhyU {\n  z-index: 600;\n}\n.Popover-module_defaultPopover__NpC6- {\n  border-width: 1px;\n  --tw-border-opacity: 1;\n  border-color: rgb(221 221 221 / var(--tw-border-opacity));\n  --tw-bg-opacity: 1;\n  background-color: rgb(248 248 248 / var(--tw-bg-opacity));\n  border-radius: 0.25rem;\n  padding-left: 1rem;\n  padding-right: 1rem;\n  padding-top: 0.5rem;\n  padding-bottom: 0.5rem;\n  min-width: 7rem;\n}\n";
var classes = {"popoverWrapper":"Popover-module_popoverWrapper__EKhyU","defaultPopover":"Popover-module_defaultPopover__NpC6-"};
styleInject(css_248z);

export { classes as default };
