"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.1
 */
'use client';

import { jsx, Fragment, jsxs } from 'react/jsx-runtime';
import { useRef } from 'react';
import clsx from 'clsx';
import { useIsTextTruncated } from '../hooks/useIsTextTruncated.mjs';
import { Tooltip } from './Tooltip/index.mjs';
import classes from './Truncate.module.css.mjs';

function Truncate({
  mode = "middle",
  children,
  endLetters = 5,
  className
}) {
  const overflowDetectionSpan = useRef(null);
  const isTruncated = useIsTextTruncated(overflowDetectionSpan);
  if (typeof children !== "string" || children.length < endLetters) {
    return className ? /* @__PURE__ */ jsx("span", { className, children }) : /* @__PURE__ */ jsx(Fragment, { children });
  }
  if (mode === "end") {
    return /* @__PURE__ */ jsx(TooltipWrapper, { tooltip: children, showTooltip: isTruncated, children: /* @__PURE__ */ jsx(
      "span",
      {
        ref: overflowDetectionSpan,
        className: clsx(classes.truncatedEnd, className),
        children
      }
    ) });
  }
  const [startSegment, endSegment] = getSplittedStringAt(
    children,
    getSplitStringPosition(children, endLetters)
  );
  return /* @__PURE__ */ jsx(TooltipWrapper, { tooltip: children, showTooltip: isTruncated, children: /* @__PURE__ */ jsx("span", { className: classes.truncatedWrapper, children: /* @__PURE__ */ jsxs("span", { className: clsx(classes.truncated, className), children: [
    startSegment && /* @__PURE__ */ jsx("span", { ref: overflowDetectionSpan, className: classes.startSegment, children: startSegment }),
    endSegment && /* @__PURE__ */ jsx("span", { className: classes.endSegment, children: endSegment })
  ] }) }) });
}
function TooltipWrapper({
  children,
  tooltip,
  showTooltip
}) {
  return /* @__PURE__ */ jsx(Tooltip, { disabled: !showTooltip, tooltip, placement: "top-start", children });
}
function getSplitStringPosition(string, endLetters) {
  const stringLength = string.length;
  const lastIndexOfSpace = string.lastIndexOf(" ");
  const lastIndexOfAngleBracket = string.lastIndexOf(">");
  if (stringLength <= endLetters) {
    return stringLength;
  }
  if (lastIndexOfSpace < 0 && lastIndexOfAngleBracket < 0) {
    return endLetters;
  }
  const positionOfDelimiter = stringLength - Math.max(lastIndexOfAngleBracket, lastIndexOfSpace);
  if (positionOfDelimiter > endLetters * 1.8) {
    return endLetters;
  }
  return positionOfDelimiter;
}
function getSplittedStringAt(str, position) {
  const startSegment = str.slice(0, -position).replace(/\s/g, "\xA0");
  const endSegment = str.slice(-1 * position).replace(/\s/g, "\xA0");
  if (!startSegment && endSegment) {
    return [endSegment];
  }
  return [startSegment, endSegment];
}

export { Truncate };
