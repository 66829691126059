"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.1
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import { createContext, useState, useMemo, useContext } from 'react';

const ComboBoxDropdownSearchContext = createContext(
  {
    hideSearch: false,
    searchValue: "",
    setSearchValue: () => null,
    drillDownDepth: 0,
    setDrillDownDepth: () => {
    },
    searchSource: "editor",
    setSearchSource: () => {
    }
  }
);
ComboBoxDropdownSearchContext.displayName = "ComboBoxDropdownSearchContext";
const ComboBoxDropdownSearchProvider = ({
  children,
  hideSearch = false
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [drillDownDepth, setDrillDownDepth] = useState(0);
  const [searchSource, setSearchSource] = useState("editor");
  const contextValue = useMemo(
    () => ({
      searchValue,
      setSearchValue,
      hideSearch,
      drillDownDepth,
      setDrillDownDepth,
      searchSource,
      setSearchSource
    }),
    [searchValue, hideSearch, drillDownDepth, searchSource]
  );
  return /* @__PURE__ */ jsx(ComboBoxDropdownSearchContext.Provider, { value: contextValue, children });
};
function useComboBoxDropdownSearchContext() {
  return useContext(ComboBoxDropdownSearchContext);
}

export { ComboBoxDropdownSearchProvider, useComboBoxDropdownSearchContext };
