"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.1
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import { createContext, useState, useEffect, useCallback, useContext } from 'react';
import usePromise from '../../../_modules/react-use-promise.mjs';
import deepEqual from 'fast-deep-equal';
import { FormulaListOptionsFactory } from '../../DataBuilder/Formula/options-factory.mjs';
import { SvgIconType } from '../../SvgIcon/index.mjs';
import { useIntegrationAppClient } from '../../../contexts/integration-app-client-context.mjs';
import { useIntegrationAppConnection } from '../../../contexts/integration-app-connection-context.mjs';
import { useIntegrationAppIntegration } from '../../../contexts/integration-app-integration-context.mjs';
import 'fuse';
import { BooleanOptionsFactory } from '../options-factories/boolean.mjs';
import { ConstantOptionFactory } from '../options-factories/constant.mjs';
import { CustomFieldOptionsFactory } from '../options-factories/custom-field.mjs';
import { EnumOptionFactory } from '../options-factories/enum.mjs';
import 'js-convert-case';
import '@integration-app/sdk';
import { SchemaOptionFactory } from '../options-factories/schema.mjs';
import { ReferenceRecordsOptionsFactory } from '../options-factories/reference-records.mjs';
import { ReferenceUdmOptionFactory } from '../options-factories/reference-udm.mjs';
import { ComboBoxTabType } from '../types.mjs';
import { getAvailableTabs, getCurrentTab } from './helpers.mjs';
import { useComboBox } from './combobox-context.mjs';
import useDataBuilder from '../../DataBuilder/data-builder-context.mjs';

const ComboBoxDropdownContext = createContext({
  availableTabs: [],
  activeTab: ComboBoxTabType.VARIABLE,
  setActiveTab: () => null,
  resetActiveTab: () => null,
  isOptionSelected: () => false,
  generateOptions: () => [[]]
});
ComboBoxDropdownContext.displayName = "ComboBoxDropdownContext";
const ComboBoxDropdownContextProvider = ({
  children
}) => {
  const { editableVariablesSchemaLocators } = useDataBuilder();
  const {
    value,
    schema,
    valueSpec,
    variablesSchema,
    variablesOnly,
    options,
    optionFactories
  } = useComboBox();
  const { client } = useIntegrationAppClient();
  const { connectionId } = useIntegrationAppConnection();
  const { integrationId } = useIntegrationAppIntegration();
  const [currentTab, setCurrentTab] = useState(null);
  const [availableTabs, setAvailableTabs] = useState(
    null
  );
  const hasVariables = variablesSchema?.type === "object" && Object.keys(variablesSchema.properties ?? {}).length > 0;
  useEffect(() => {
    void getAvailableTabs({
      schema,
      options,
      optionFactories,
      variablesOnly,
      hasVariables,
      client,
      connectionId,
      integrationId
    }).then((tabs) => {
      setAvailableTabs(tabs);
    });
  }, [
    schema,
    options,
    optionFactories,
    variablesOnly,
    hasVariables,
    client,
    integrationId,
    connectionId
  ]);
  const activeTab = availableTabs ? getCurrentTab(availableTabs, currentTab, value) : null;
  const generateOptions = function(searchValue, drilldownStack) {
    return usePromise(
      () => getOptions({
        activeTab,
        // @ts-expect-error TS(2345): Argument of type 'DataSchema | undefined' is not a... Remove this comment to see the full error message
        schema,
        variablesSchema,
        searchValue,
        valueSpec,
        drilldownStack,
        options,
        optionFactories,
        editableVariablesSchemaLocators
      }),
      [
        activeTab,
        JSON.stringify(schema),
        JSON.stringify(variablesSchema),
        searchValue,
        JSON.stringify(valueSpec),
        drilldownStack.length.toString(),
        JSON.stringify(options),
        optionFactories?.length,
        JSON.stringify(editableVariablesSchemaLocators)
      ]
    );
  };
  const isOptionSelected = useCallback(
    function(option) {
      return deepEqual(option.value, value);
    },
    [value]
  );
  return /* @__PURE__ */ jsx(
    ComboBoxDropdownContext.Provider,
    {
      value: {
        availableTabs,
        activeTab,
        setActiveTab: setCurrentTab,
        resetActiveTab: () => setCurrentTab(null),
        isOptionSelected,
        generateOptions
      },
      children
    }
  );
};
function useComboBoxDropdownContext() {
  return useContext(ComboBoxDropdownContext);
}
async function getOptions({
  activeTab,
  schema,
  variablesSchema,
  searchValue,
  valueSpec,
  drilldownStack,
  options: staticOptions,
  optionFactories,
  editableVariablesSchemaLocators
}) {
  const factories = [...optionFactories];
  switch (activeTab) {
    case ComboBoxTabType.OPTIONS:
      if (schema?.referenceUdm) {
        factories.unshift(new ReferenceUdmOptionFactory(schema));
      } else if (schema?.referenceRecords) {
        factories.push(new ReferenceRecordsOptionsFactory({ schema }));
      } else if (schema?.enum) {
        factories.push(new EnumOptionFactory({ schema }));
      } else if (schema?.type === "boolean") {
        factories.push(new BooleanOptionsFactory());
      } else if (staticOptions) {
        factories.push(new ConstantOptionFactory(staticOptions));
      }
      break;
    case ComboBoxTabType.VARIABLE:
      factories.push(
        new SchemaOptionFactory({
          schema: variablesSchema,
          editableSchemaLocators: editableVariablesSchemaLocators
        })
      );
      break;
    case ComboBoxTabType.FORMULA:
      factories.push(new FormulaListOptionsFactory());
      break;
    case ComboBoxTabType.CUSTOM:
      factories.push(new CustomFieldOptionsFactory());
      break;
  }
  const options = [];
  async function getOptionsFromFactory(factory) {
    const result = [];
    result.push(
      ...await factory.getOptions({ input: searchValue, spec: valueSpec })
    );
    if (searchValue) {
      const unfilteredOptions = await factory.getOptions({
        input: "",
        spec: valueSpec
      });
      for (const option of unfilteredOptions) {
        if (option.drilldownOptionFactories) {
          for (const drilldownFactory of option.drilldownOptionFactories) {
            result.push(...await getOptionsFromFactory(drilldownFactory));
          }
        }
      }
    }
    return result;
  }
  if (drilldownStack.length > 0) {
    options.push({
      label: "Back",
      iconType: SvgIconType.ChevronLeft,
      drillup: true
    });
    const option = drilldownStack[drilldownStack.length - 1];
    for (const drilldownFactory of option?.drilldownOptionFactories ?? []) {
      options.push(...await getOptionsFromFactory(drilldownFactory));
    }
  } else {
    for (const optionFactory of [...factories]) {
      options.push(...await getOptionsFromFactory(optionFactory));
    }
  }
  return options;
}

export { ComboBoxDropdownContextProvider, useComboBoxDropdownContext };
