"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.1
 */
'use client';

import { jsx, jsxs, Fragment } from 'react/jsx-runtime';
import { IntegrationElementLevel } from '@integration-app/sdk';
import { useIntegrationElementContext } from '../integration-element-context.mjs';
import { DataCollectionSelect } from './DataCollectionSelect.mjs';
import { UdmSelect } from './UdmSelect.mjs';
import { AdvancedOptions } from '../../AdvancedOptions.mjs';
import { SimpleInput } from '../../SimpleInput/SimpleInput.mjs';
import '../../DataBuilderUI/index.mjs';
import '../../Textarea/index.mjs';
import '../../SimpleInput/SimpleInput.module.css.mjs';
import 'clsx';
import 'react';
import '../../Radio/index.mjs';

function DataSourceConfig(props) {
  return /* @__PURE__ */ jsx("div", { className: "flex flex-col gap-2 mb-2", children: /* @__PURE__ */ jsx(LevelSpecificDataSourceConfig, { ...props }) });
}
function LevelSpecificDataSourceConfig(props) {
  const { level } = useIntegrationElementContext();
  const { value, onChange, configureEvents } = props;
  function patchValue(patch) {
    onChange({
      ...value ?? {},
      ...patch
    });
  }
  switch (level) {
    case IntegrationElementLevel.UNIVERSAL:
      return /* @__PURE__ */ jsxs(Fragment, { children: [
        /* @__PURE__ */ jsx(
          UdmSelect,
          {
            value: value?.udm,
            onChange: (udm) => patchValue({ udm })
          }
        ),
        /* @__PURE__ */ jsx(AdvancedOptions, { children: configureEvents && /* @__PURE__ */ jsx(DataSourceConfigureEvents, { value, onChange }) })
      ] });
    case IntegrationElementLevel.CONNECTOR:
    case IntegrationElementLevel.CONNECTION:
      return /* @__PURE__ */ jsxs(Fragment, { children: [
        /* @__PURE__ */ jsx(
          DataCollectionSelect,
          {
            value,
            onChange: (value2) => patchValue(value2),
            variablesSchema: props.variablesSchema
          }
        ),
        configureEvents && /* @__PURE__ */ jsx(
          AdvancedOptions,
          {
            openByDefault: !!(value?.pullUpdatesIntervalSeconds ?? value?.fullSyncIntervalSeconds),
            children: /* @__PURE__ */ jsx(DataSourceConfigureEvents, { value, onChange })
          }
        )
      ] });
    default:
      return null;
  }
}
function DataSourceConfigureEvents({
  value,
  onChange
}) {
  return /* @__PURE__ */ jsxs("div", { className: "flex flex-col gap-2", children: [
    /* @__PURE__ */ jsx("p", { children: "Change how often we pulls updates from the data source." }),
    /* @__PURE__ */ jsx(
      SimpleInput,
      {
        label: "Pull updates interval (seconds)",
        value: value?.pullUpdatesIntervalSeconds?.toString(),
        onChange: (pullUpdatesIntervalSeconds) => onChange({
          ...value,
          pullUpdatesIntervalSeconds: Number(pullUpdatesIntervalSeconds)
        })
      }
    ),
    /* @__PURE__ */ jsx(
      SimpleInput,
      {
        label: "Full sync interval (seconds)",
        value: value?.fullSyncIntervalSeconds?.toString(),
        onChange: (fullSyncIntervalSeconds) => onChange({
          ...value,
          fullSyncIntervalSeconds: Number(fullSyncIntervalSeconds)
        })
      }
    )
  ] });
}

export { DataCollectionSelect, DataSourceConfig, DataSourceConfigureEvents };
