"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.1
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import { SvgIcon } from '../SvgIcon/index.mjs';

function DropdownTabRoot({ children }) {
  return children;
}
function DropdownTabIcon(props) {
  if ("type" in props) {
    return /* @__PURE__ */ jsx(DropdownTabIconSvg, { ...props });
  }
  if ("src" in props) {
    return /* @__PURE__ */ jsx(DropdownTabIconImg, { ...props });
  }
  return null;
}
function DropdownTabIconSvg({ type }) {
  return /* @__PURE__ */ jsx(SvgIcon, { type });
}
function DropdownTabIconImg({ src }) {
  return /* @__PURE__ */ jsx("img", { src, alt: "" });
}
const DropdownTab = Object.assign(DropdownTabRoot, {
  Icon: DropdownTabIcon
});

export { DropdownTab };
